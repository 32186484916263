import React from 'react';

import { useStore } from 'effector-react';
import { SnackbarProvider } from 'notistack';

import { modalApi, Modals$ } from '../../effector/modals';
import { MODALS } from '../../utils/types';
import { AddQuestion } from '../Forms//AddQuestion';
import { AccCalcRebalance } from '../Forms/AccCalcRebalance';
import { AccountRebalance, AccountBlock, ServiceStopCS } from '../Forms/AccountManagementAction';
import { ActivateStrategy } from '../Forms/ActivateStrategy';
import { AddCategory } from '../Forms/AddCategory';
import { AddInvestboxForm } from '../Forms/AddInvestbox';
import { AddSignalForm } from '../Forms/AddSignal';
import { AddStrategyForm } from '../Forms/AddStrategy';
import { AddUser } from '../Forms/AddUser';
import { ApiKeys } from '../Forms/ApiKeys';
import { ChangePasswordForm } from '../Forms/ChangePassword';
import { ChangePosition } from '../Forms/ChangePosition';
import { CommentForm } from '../Forms/Comment';
import { RemoveCategory } from '../Forms/RemoveCategory';
import { RemoveDelayedSignal } from '../Forms/RemoveDelayedSignal';
import { RemovePositionForm } from '../Forms/RemovePosition';
import { RemoveQuestion } from '../Forms/RemoveQuestion';
import { RemoveSchedule } from '../Forms/RemoveSchedule';
import { RemoveUserForm } from '../Forms/RemoveUserForm';
import { ScheduleAdd } from '../Forms/ScheduleAdd';
import { ScheduleAddWeekend } from '../Forms/ScheduleAddWeekend';
import { ScheduleEdit } from '../Forms/ScheduleEdit';
import { ScheduleRemoveWeekend } from '../Forms/ScheduleRemoveWeekend';
import { SignalApproved } from '../Forms/SignalApproved';
import { Split } from '../Forms/Split';
import { UpdateHistory } from '../Forms/UpdateHistory';
import { UploadTrack } from '../Forms/UploadTrack';
import { Modal } from '../Modal';

export const ModalManager: React.FC = ({}) => {
  const modalsState = useStore(Modals$);

  return (
    <SnackbarProvider maxSnack={3}>
      <Modal
        isOpen={modalsState[MODALS.REMOVE_POSITION]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Закрытие позиции"
      >
        <RemovePositionForm />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.CHANGE_PASSWORD]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Смена пароля"
      >
        <ChangePasswordForm />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.NEW_SIGNAL]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Новый сигнал"
      >
        <AddSignalForm />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.REMOVE_DELAYED_SIGNAL]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Закрытие отложенного сигнала"
      >
        <RemoveDelayedSignal />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.CHANGE_POSITION]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Замена позиции"
      >
        <ChangePosition />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.SPLIT]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Сплит"
      >
        <Split />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.ADD_USER]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Пользователь"
      >
        <AddUser />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.REMOVE_USER]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Удаление пользователя"
      >
        <RemoveUserForm />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.SIGNAL_APPROVED]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Подача сигнала"
      >
        <SignalApproved />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.UPLOAD_TRACK]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Загрузка трека"
      >
        <UploadTrack />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.ADD_STRATEGY]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Стратегия"
      >
        <AddStrategyForm />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.OPEN_STRATEGY]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Параметры стратегии"
      >
        <ActivateStrategy />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.ADD_INVESTBOX]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Инвест-копилка"
      >
        <AddInvestboxForm />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.UPDATE_HISTORY]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Стратегии"
      >
        <UpdateHistory />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.ACCOUNT_REBALANCE]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Управление аккаунтом"
      >
        <AccountRebalance />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.ACCOUNT_BLOCK]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Управление аккаунтом"
      >
        <AccountBlock />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.ACCOUNT_CALC_REBALANCE]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Балансировка клиента"
      >
        <AccCalcRebalance />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.SERVICE_STOP_CS]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Управление сервисом клиентов"
      >
        <ServiceStopCS />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.SCHEDULE_EDIT]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Редактирование расписания"
      >
        <ScheduleEdit />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.SCHEDULE_ADD]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Добавление расписания"
      >
        <ScheduleAdd />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.SCHEDULE_ADD_WEEKEND]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Добавление неторгового дня"
      >
        <ScheduleAddWeekend />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.SCHEDULE_REMOVE]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Удаление расписания"
      >
        <RemoveSchedule />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.SCHEDULE_REMOVE_WEEKEND]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Удаление неторгового дня"
      >
        <ScheduleRemoveWeekend />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.FAQ_DELETE_CATEGORY]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Удаление категории"
      >
        <RemoveCategory />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.FAQ_DELETE_QUESTION]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Удаление вопроса"
      >
        <RemoveQuestion />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.FAQ_ADD_CATEGORY]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Управление категорией"
      >
        <AddCategory />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.FAQ_ADD_QUESTION]}
        className="modal__min-container"
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Управление вопросом"
      >
        <AddQuestion />
      </Modal>

      <Modal
        isOpen={modalsState[MODALS.COMMENT]}
        className="modal__min-container"
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Обновление комментария"
      >
        <CommentForm />
      </Modal>
      <Modal
        isOpen={modalsState[MODALS.API_KEYS]}
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title="Сохраните сгенерированный Api Key"
      >
        <ApiKeys />
      </Modal>
    </SnackbarProvider>
  );
};
