import React from 'react';

import { RadioGroup, FormControlLabel, Radio, FormControl } from '@mui/material';
import { Controller } from 'react-hook-form';

import { useFormContext } from '../FormProvider';

interface Props {
  name: string;
  options: { value: string; label: React.ReactNode }[];
  row?: boolean;
  fullWidth?: boolean;
  margin?: 'none' | 'dense' | 'normal';
}

export const ControlledRadioGroupField: React.FC<Props> = ({
  name,
  options,
  row = false,
  fullWidth = true,
  margin = 'normal',
}) => {
  const { control, errors } = useFormContext();

  return (
    <FormControl fullWidth={fullWidth} margin={margin}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RadioGroup {...field} row={row}>
            {options.map((option) => (
              <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
            ))}
          </RadioGroup>
        )}
      />
      {errors?.[name] && <p style={{ color: 'red' }}>{errors[name]?.message}</p>}
    </FormControl>
  );
};
