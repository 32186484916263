import React from 'react';

import { FormControl } from '@mui/material';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';

import { useFormContext } from '../FormProvider';

import 'dayjs/locale/ru';

dayjs.locale('ru');

type Props = Omit<DateTimePickerProps<Date | null>, 'value' | 'onChange' | 'maxDate' | 'minDate'> & {
  name: string;
  label: string;
  maxDate?: string | Date;
  minDate?: string | Date;
  fullWidth?: boolean;
  displayTime?: boolean;
  margin?: 'none' | 'dense' | 'normal';
  onChange?: (date: Date | null) => void;
};

export const ControlledDateTimeField: React.FC<Props> = ({
  name,
  label,
  disabled,
  onChange,
  minDate,
  maxDate,
  fullWidth = true,
  displayTime = false,
  margin = 'normal',
  format = 'DD.MM.YYYY HH:mm',
}) => {
  const { control, errors } = useFormContext();

  return (
    <FormControl fullWidth={fullWidth} margin={margin}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DateTimePicker
            {...field}
            label={label}
            value={field.value ? dayjs(field.value) : null}
            onChange={(date) => {
              field.onChange(date);
              onChange?.(date?.toDate() || new Date());
            }}
            ampm={false}
            minDate={minDate ? dayjs(minDate) : undefined}
            maxDate={maxDate ? dayjs(maxDate) : undefined}
            views={displayTime ? ['year', 'month', 'day', 'hours', 'minutes'] : ['year', 'month', 'day']}
            disabled={disabled}
            format={format}
            slotProps={{
              textField: {
                fullWidth,
                error: !!errors?.[name],
                helperText: !!errors?.[name]?.message,
              },
            }}
          />
        )}
      />
    </FormControl>
  );
};
