import * as yup from 'yup';

import { ERRORS_TEXTS } from '../../../const/validation';
import { BannerType } from '../../../types/banner';

export const schema = yup.object({
  title: yup.string().required(ERRORS_TEXTS.required),
  subtitle: yup.string().required(ERRORS_TEXTS.required),
  buttonText: yup.string().required(ERRORS_TEXTS.required),
  url: yup.string().required(ERRORS_TEXTS.required),
  utm: yup.string().required(ERRORS_TEXTS.required),
  type: yup.mixed<BannerType>().oneOf(Object.values(BannerType)).required(ERRORS_TEXTS.required),
  order: yup.number().required(ERRORS_TEXTS.required).positive(ERRORS_TEXTS.number).typeError(ERRORS_TEXTS.number),
});
