import React, { useEffect, useState, useRef, SyntheticEvent, useCallback } from 'react';

import {
  TextField,
  Box,
  CircularProgress,
  Autocomplete,
  FormControl,
  AutocompleteInputChangeReason,
} from '@mui/material';
import { useStore } from 'effector-react';

import { searchApi, Search$ } from '../../effector/search';
import { Suggestion } from '../../types/suggestion';

const SEARCH_LENGTH = 1;
const DELAY = 500;

type Props = {
  strategyId?: string;
  classCodes?: string[];
  showSelectedValue?: boolean;
  forceClear?: boolean;
  forceSearchValue?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  margin?: 'none' | 'dense' | 'normal';
  onClear: () => void;
  onSearch: (securityKey: string, suggestion?: Suggestion) => void;
};

export const SearchInstrumentField: React.FC<Props> = ({
  strategyId,
  classCodes,
  forceClear,
  forceSearchValue,
  disabled,
  onClear,
  onSearch,
  fullWidth = true,
  margin = 'normal',
  showSelectedValue = true,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const timerId = useRef<NodeJS.Timeout | null>(null);

  const searchStore = useStore(Search$);

  const search = useCallback(
    (value: string) => {
      if (value.length < SEARCH_LENGTH) {
        return;
      }

      setLoading(true);
      const params = {
        strategyId,
        classCodes,
        pattern: value,
      };

      searchApi.get(params).finally(() => setLoading(false));
    },
    [classCodes, strategyId],
  );

  const updateTimer = useCallback(
    (value: string) => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }

      timerId.current = setTimeout(() => {
        search(value);
      }, DELAY);
    },
    [search],
  );

  const handleOnChange = useCallback(
    (_: SyntheticEvent, value: string, __: AutocompleteInputChangeReason) => {
      setSearchValue(value);
      updateTimer(value);
    },
    [updateTimer],
  );

  const handleSearchClick = useCallback(
    (elem: Suggestion) => {
      const search = showSelectedValue ? elem.name : '';
      setSearchValue(search);
      onSearch(`${elem.symbol} ${elem.classCode} QUIK`, elem);
    },
    [onSearch, showSelectedValue],
  );

  const clearSearch = useCallback(() => {
    onClear();
    setSearchValue('');
    searchApi.clearSuggestion('');
    searchApi.clearDetails('');
  }, [onClear]);

  useEffect(() => {
    if (forceClear) {
      clearSearch();
    }
  }, [clearSearch, forceClear]);

  useEffect(() => {
    if (forceSearchValue) {
      setSearchValue(forceSearchValue);
    }
  }, [forceSearchValue]);

  const suggestions = searchStore?.suggestions?.[searchValue] || [];

  return (
    <FormControl fullWidth={fullWidth} margin={margin}>
      <Box sx={{ position: 'relative', width: '100%' }}>
        <Autocomplete
          freeSolo
          options={suggestions}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name || '')}
          loading={loading}
          disabled={disabled}
          inputValue={searchValue}
          onInputChange={handleOnChange}
          onChange={(event, value) => {
            if (value) {
              handleSearchClick(value as Suggestion);
            } else {
              clearSearch();
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Поиск инструмента"
              placeholder="Введите название инструмента"
              fullWidth
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          noOptionsText="По вашему запросу ничего не найдено"
        />
      </Box>
    </FormControl>
  );
};
