import { useEffect, useRef } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Analytic } from '../../../types/analytic';
import { AnalyticForm } from '../Form';

interface Props {
  analytic?: Analytic | null;
  onClose: () => void;
  onUpdate: () => void;
}

export function AnalyticDialog({ analytic, onClose, onUpdate }: Props) {
  const descriptionElementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const { current: descriptionElement } = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }
  }, []);

  return (
    <Dialog
      open
      onClose={onClose}
      scroll="paper"
      maxWidth="lg"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title" align="center">
        Аналитика
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          <AnalyticForm analytic={analytic} onUpdate={onUpdate} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
