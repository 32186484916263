import React, { createContext, useContext } from 'react';

import { Control } from 'react-hook-form';

interface FormContextProps {
  control: Control<any>;
  errors: Record<string, any>;
}

const FormContext = createContext<FormContextProps | null>(null);

export const FormProvider: React.FC<{
  control: Control<any>;
  errors: Record<string, any>;
  children: React.ReactNode;
}> = ({ control, errors, children }) => {
  return <FormContext.Provider value={{ control, errors }}>{children}</FormContext.Provider>;
};

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
};
