import React from 'react';

import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Controller } from 'react-hook-form';

import { useFormContext } from '../FormProvider';

interface Props {
  name: string;
  label: string;
  options: { value: string; label: string }[];
  fullWidth?: boolean;
  multiple?: boolean;
  margin?: 'none' | 'dense' | 'normal';
}

export const ControlledSelectField: React.FC<Props> = ({
  name,
  label,
  options,
  multiple = false,
  fullWidth = true,
  margin = 'normal',
}) => {
  const { control, errors } = useFormContext();

  return (
    <FormControl fullWidth={fullWidth} margin={margin}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select {...field} labelId={`${name}-label`} error={!!errors?.[name]} multiple={multiple}>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {errors?.[name] && <p style={{ color: 'red' }}>{errors[name]?.message}</p>}
    </FormControl>
  );
};
