import React, { useEffect, useMemo } from 'react';

import { useStore } from 'effector-react';
import { Navigate, Route, Routes as RoutesComponent } from 'react-router-dom';

import { ClientDetails } from './ClientDetails';
import { ClientAccount } from './ClientInfo';
import { Clients } from './Clients';
import { Errors } from './Errors';
import { Money } from './Money';
import { ProblemClients } from './ProblemClients';
import { Navigation } from '../../components/Navigation';
import { User$ } from '../../effector/user';
import { UserRoles } from '../../types/userRoles';
import { checkPermissions, UserAction } from '../../utils/permissions';
import { PrivateRoute } from '../PrivateRoute';
import {
  PAGE_STATISTIC_CLIENTS,
  PAGE_STATISTIC_ERRORS,
  PAGE_STATISTIC_MONEY,
  PAGE_STATISTIC_CLIENT_DETAILS,
  PAGE_STATISTIC_CLIENT_INFO,
  PAGE_STATISTIC_PROBLEMS,
} from '../Routes';

export const Statistic: React.FC = () => {
  const user = useStore(User$);

  const items = useMemo(
    () => [
      {
        name: 'Деньги в стратегиях',
        path: PAGE_STATISTIC_MONEY,
        action: UserAction.statisticMoney,
      },
      {
        name: 'Клиенты',
        path: PAGE_STATISTIC_CLIENTS,
        action: UserAction.statisticClients,
      },
      {
        name: 'Статистика по клиенту',
        path: PAGE_STATISTIC_CLIENT_DETAILS,
        action: UserAction.statisticClientDetails,
      },
      {
        name: 'Клиентские данные',
        path: PAGE_STATISTIC_CLIENT_INFO,
        action: UserAction.statisticClientInfo,
      },
      {
        name: 'Проблемные клиенты',
        path: PAGE_STATISTIC_PROBLEMS,
        action: UserAction.statisticProblemClients,
      },
      {
        name: 'Ошибки',
        path: PAGE_STATISTIC_ERRORS,
        action: UserAction.statisticErrors,
      },
    ],
    [],
  );

  const navigations = useMemo(() => {
    if (user?.role) {
      return items.filter((item) => checkPermissions(user.role, item.action));
    }

    return [];
  }, [user, items]);

  const redirectUrl = useMemo(() => {
    if (user?.role !== UserRoles.Developer) {
      return PAGE_STATISTIC_MONEY;
    }
    return PAGE_STATISTIC_CLIENTS;
  }, [user]);

  return (
    <div className="statistic">
      <div className="statistic__navigation">
        <Navigation items={navigations} />
      </div>
      <div>
        <RoutesComponent>
          <Route
            path={PAGE_STATISTIC_MONEY}
            element={<PrivateRoute component={Money} userAction={UserAction.statisticMoney} />}
          />
          <Route
            path={PAGE_STATISTIC_CLIENTS}
            element={<PrivateRoute component={Clients} userAction={UserAction.statisticClients} />}
          />
          <Route
            path={`${PAGE_STATISTIC_CLIENT_DETAILS}/*`}
            element={<PrivateRoute component={ClientDetails} userAction={UserAction.statisticClientDetails} />}
          />
          <Route
            path={`${PAGE_STATISTIC_CLIENT_INFO}/:agreementId?`}
            element={<PrivateRoute component={ClientAccount} userAction={UserAction.statisticClientInfo} />}
          />
          <Route
            path={PAGE_STATISTIC_PROBLEMS}
            element={<PrivateRoute component={ProblemClients} userAction={UserAction.statisticProblemClients} />}
          />
          <Route
            path={`${PAGE_STATISTIC_ERRORS}/*`}
            element={<PrivateRoute component={Errors} userAction={UserAction.statisticErrors} />}
          />
          <Route path="*" element={<Navigate replace to={redirectUrl} />} />
        </RoutesComponent>
      </div>
    </div>
  );
};
