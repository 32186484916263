/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore, createApi, createEffect, forward } from 'effector';

import { modalApi } from './modals';
import { closePosition } from '../api';
import { SignalClose } from '../api/types/signal';
import { Position } from '../types/position';

type TPositions$State = {
  page: number;
  pageSize: number;
  totalPage: number;
  positions: Position[];
};

const initialState: TPositions$State = {
  page: 0,
  pageSize: 0,
  totalPage: 0,
  positions: [],
};

export const Positions$ = createStore<TPositions$State>(initialState);

const { add, close, update } = createApi(Positions$, {
  // @ts-ignore
  add: (state, position) => {
    if (position.result && position.result.success) {
      return {
        ...state,
        positions: [...state.positions, position],
      };
    }

    return state;
  },
  // @ts-ignore
  close: (state, { result, params }) => {
    const updatedState = { ...state };

    if (result.success) {
      // @ts-ignore
      updatedState.positions = updatedState.positions.filter((item) => item.securityKey !== params.securityKey);
      // @ts-ignore
      modalApi.hide('');
    }

    return updatedState;
  },
  // @ts-ignore
  update: (state, position) => {
    const updatedState = { ...state };
    // @ts-ignore
    const positions = updatedState.positions.filter((item) => item.securityKey !== position.securityKey2);

    updatedState.positions = [...positions, position];
    // @ts-ignore
    modalApi.hide('');

    return updatedState;
  },
});

export const positionsApi = {
  close: createEffect<SignalClose, {}, {}>().use(closePosition),
  add: createEffect(),
  update: createEffect(),
};

forward({
  from: positionsApi.close.done,
  to: close,
});

forward({
  from: positionsApi.add,
  to: add,
});

forward({
  from: positionsApi.update,
  to: update,
});
