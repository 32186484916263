export const FIELDS_LIMITS = {
  name: 128,
  phone: 32,
  description: 1024,
  passwordMinLength: 6,
  passwordMaxLength: 100,
  infoMaxLength: 51200,
};

export const ERRORS_TEXTS = {
  passwordMinLength: `Слишком короткий пароль`,
  passwordMaxLength: `Слишком длинный пароль`,
  passwordMatches: 'Пароли не совпадают',
  infoMaxLength: 'Длина описания не может быть больше 50Кб',
  formatInn: 'Некорректный номер ИНН',
  formatOgrn: 'Некорректный номер ОГРН',
  formatKpp: 'Некорректный код КПП',
  formatGuid: 'Некорректный GUID',
  required: `Поле должно быть заполнено`,
  emailInvalid: 'Некорректный email',
  number: 'Доступны только числа',
  positive: 'Доступны только числа 0 и больше',
  minItems: 'Должен быть выбран хотя бы 1 элемент',
};
