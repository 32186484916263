/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, createStore, createEffect, forward } from 'effector';

import { addInvestbox, getInvestboxesAdministration, updateInvestbox } from '../api';
import { PageParams } from '../components/TableGrid';
import { AggregateInvestbox } from '../types/aggregateInvestbox';
import { StandartResponse } from '../types/standartResponse';

type TAggregateInvestboxes$State = {
  total: number;
  investboxes: AggregateInvestbox[];
};

const initial: TAggregateInvestboxes$State = {
  total: 0,
  investboxes: [],
};

export const aggregateInvestboxes = createStore<TAggregateInvestboxes$State>(initial);

const { update, remove } = createApi(aggregateInvestboxes, {
  // @ts-ignore
  update: (state, { result }: { result: StandartResponse<AggregateInvestbox> }) => {
    if (result && result.success && result.result?.id) {
      const id = result?.result?.id;
      // @ts-ignore
      const update = state.investboxes.filter((s) => s.id === id);
      if (update) {
        // #todo
      }
    }

    return state;
  },
  // @ts-ignore
  remove: (state, id: string) => {
    if (id) {
      // @ts-ignore
      const result = state.investboxes.filter((s) => s.id === id);
      if (result.length !== state.total) {
        return {
          total: result.length,
          investboxes: result,
        };
      }
    }

    return state;
  },
});

export const aggregateInvestboxesApi = {
  get: createEffect<PageParams, any, any>().use(getInvestboxesAdministration),
  add: createEffect<Partial<AggregateInvestbox>, StandartResponse<AggregateInvestbox>, any>().use(addInvestbox),
  remove: createEffect<string, any, any>(),
  update: createEffect<Partial<AggregateInvestbox>, StandartResponse<AggregateInvestbox>, any>().use(updateInvestbox),
};

forward({
  from: aggregateInvestboxesApi.update.done,
  to: update,
});

forward({
  from: aggregateInvestboxesApi.remove,
  to: remove,
});
