import React from 'react';

import { useStore } from 'effector-react';

import { CopyToClipboard } from '../../components/CopyToClipboard';
import { Modals$, TModal$State } from '../../effector/modals';

export const ApiKeys: React.FC = () => {
  const modalsState = useStore<TModal$State<{ apiKey: string }>>(Modals$);

  return (
    <div className="remove-delayed-signal">
      <div className="remove-delayed-signal__description">
        <p style={{ marginBottom: 20 }}>После закрытия ключ нельзя будет восстановить</p>
        {modalsState.data?.apiKey && <p>Api Key: {modalsState.data.apiKey}</p>}
      </div>
      <div className="remove-delayed-signal__button-wrapper">
        <CopyToClipboard className="button__large button__primary" text={modalsState.data?.apiKey || ''} />
      </div>
    </div>
  );
};
