import { useMutation, useQuery } from 'react-query';

import {
  getBranches,
  getClientsStatistic,
  getStatisticServicesDictionary,
  getStatisticStrategiesDictionary,
} from '../../../../api';
import { PageParams } from '../../../../components/TableGrid';

export const useClientsStatistic = () =>
  useMutation(['getClientsStatistic'], (data: PageParams) => getClientsStatistic(data));

export const useBranchesDictionary = () => useQuery(['getBranches'], () => getBranches());

export const useStrategiesDictionary = () =>
  useQuery(['getStatisticStrategiesDictionary'], () => getStatisticStrategiesDictionary());

export const useServicesDictionary = () =>
  useQuery(['getStatisticServicesDictionary'], () => getStatisticServicesDictionary());
