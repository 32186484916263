import { useMutation, useQuery } from 'react-query';

import { getStatisticProblemsDictionary, getProblemClientsStatistic } from '../../../../api';
import { PageParams } from '../../../../components/TableGrid';

export const useProblemClientsStatistic = () =>
  useMutation('getProblemClientsStatistic', (params: PageParams) => getProblemClientsStatistic(params));

export const useProblemsDictionary = () =>
  useQuery(['getStatisticProblemsDictionary'], () => getStatisticProblemsDictionary());
