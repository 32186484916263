/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useMemo } from 'react';

import { useStore } from 'effector-react';
import { MRT_ColumnDef } from 'material-react-table';

import { SortType } from '../../../components/TableGrid';
import { TableGridLocal } from '../../../components/TableGridLocal';
import { getCorrectServiceType, onlyUnique, sortColumnFn } from '../../../components/TableGridLocal/filters';
import { Money$, ProductStat, moneyApi } from '../../../effector/money';
import { boolToString, formatMoney } from '../../../utils';

export const Money: React.FC = () => {
  const moneyStore = useStore(Money$);

  const loadMoney = useCallback(() => {
    moneyApi.get({
      pageNumber: 0,
      pageSize: 1000,
      sortFieldName: 'totalSUR',
      sortDirection: SortType.Desk,
    });
  }, []);

  const updatedData = useMemo(() => {
    return moneyStore.pageStats.map((item) => ({
      ...item,
      currency: item.currency === 'SUR' ? 'RUB' : item.currency,
    }));
  }, [moneyStore.pageStats]);

  const currencies = useMemo(() => updatedData.map(({ currency }) => currency).filter(onlyUnique), [updatedData]);

  const columns = useMemo<MRT_ColumnDef<ProductStat>[]>(() => {
    const items: MRT_ColumnDef<ProductStat>[] = [
      {
        accessorKey: 'name',
        header: 'Название',
        footer: 'Всего',
        filterVariant: 'text',
      },
      {
        accessorFn: (originalRow) => getCorrectServiceType(originalRow.productType),
        header: 'Тип продукта',
        filterVariant: 'select',
        filterSelectOptions: ['Автоследование', 'Автоконсультирование', 'Накопилка'],
        size: 150,
        Cell: ({ cell }) => getCorrectServiceType(cell.row.original.productType),
      },
      {
        accessorKey: 'author',
        header: 'Автор',
        filterVariant: 'text',
        size: 150,
      },
      {
        accessorKey: 'currency',
        header: 'Валюта',
        filterVariant: 'multi-select',
        filterSelectOptions: currencies,
        size: 50,
      },
      {
        accessorKey: 'clientCount',
        header: 'Клиентов всего',
        Footer: ({ table }) => {
          const rows = table.getSortedRowModel().rows;
          const count = rows.reduce((prev, cur) => prev + cur.original.clientCount, 0);

          return count;
        },
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        size: 50,
      },
      {
        header: 'Денег всего',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        accessorFn: (originalRow) => {
          const value = originalRow.currency === 'SUR' ? originalRow.valueSUR : originalRow.value;
          return value;
        },
        sortingFn: (col, col2) => {
          return sortColumnFn(col.original, col2.original, 'valueSUR');
        },
        Cell: ({ cell }) => {
          const value = cell.row.original.currency === 'SUR' ? cell.row.original.valueSUR : cell.row.original.value;

          if (!value) {
            return '';
          }

          return formatMoney(value, cell.row.original.currency);
        },
        Footer: ({ table }) => {
          const rows = table.getSortedRowModel().rows;
          const count = rows.reduce((prev, cur) => prev + cur.original.valueSUR, 0);

          return formatMoney(count);
        },
        size: 100,
      },
      {
        header: 'Мин. сумма',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        accessorFn: (originalRow) => {
          const value =
            originalRow.currency === 'SUR' ? originalRow.subscriptionThresholdSUR : originalRow.subscriptionThreshold;
          return value;
        },
        sortingFn: (col, col2) => {
          return sortColumnFn(col.original, col2.original, 'subscriptionThresholdSUR');
        },
        Cell: ({ cell }) => {
          const value =
            cell.row.original.currency === 'SUR'
              ? cell.row.original.subscriptionThresholdSUR
              : cell.row.original.subscriptionThreshold;

          if (!value) {
            return '';
          }

          return formatMoney(value, cell.row.original.currency);
        },
        size: 100,
      },
      {
        header: 'Средний',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        accessorFn: (originalRow) => {
          const value = originalRow.currency === 'SUR' ? originalRow.averageVolumeSUR : originalRow.averageVolume;
          return value;
        },
        sortingFn: (col, col2) => {
          return sortColumnFn(col.original, col2.original, 'averageVolumeSUR');
        },
        Cell: ({ cell }) => {
          const value =
            cell.row.original.currency === 'SUR' ? cell.row.original.averageVolumeSUR : cell.row.original.averageVolume;

          if (!value) {
            return '';
          }

          return formatMoney(value, cell.row.original.currency);
        },
        size: 100,
      },
      {
        header: 'Медианный',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        accessorFn: (originalRow) => {
          const value = originalRow.currency === 'SUR' ? originalRow.medianVolumeSUR : originalRow.medianVolume;
          return value;
        },
        sortingFn: (col, col2) => {
          return sortColumnFn(col.original, col2.original, 'medianVolumeSUR');
        },
        Cell: ({ cell }) => {
          const value =
            cell.row.original.currency === 'SUR' ? cell.row.original.medianVolumeSUR : cell.row.original.medianVolume;

          if (!value) {
            return '';
          }

          return formatMoney(value, cell.row.original.currency);
        },
        size: 100,
      },
      {
        accessorFn: (originalRow) => boolToString(originalRow.open),
        header: 'Запущена',
        filterVariant: 'select',
        filterSelectOptions: ['Да', 'Нет'],
        size: 50,
        Cell: ({ cell }) => boolToString(cell.row.original.open),
      },
      {
        accessorFn: (originalRow) => boolToString(originalRow.isRestricted, { yes: 'Закрытая', no: 'Открытая' }),
        header: 'Видимость',
        filterVariant: 'select',
        filterSelectOptions: ['Закрытая', 'Открытая'],
        size: 50,
        Cell: ({ cell }) => boolToString(cell.row.original.isRestricted, { yes: 'Закрытая', no: 'Открытая' }),
      },
      {
        accessorFn: (originalRow) => boolToString(originalRow.isBlogger),
        header: 'Стратегия блогера',
        filterVariant: 'select',
        filterSelectOptions: ['Да', 'Нет'],
        size: 50,
        Cell: ({ cell }) => boolToString(cell.row.original.isBlogger),
      },
      {
        accessorKey: 'demand',
        header: 'Популярность',
        size: 50,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
    ];
    return items;
  }, [currencies]);

  useEffect(() => {
    loadMoney();
  }, [loadMoney]);

  return (
    <div style={{ marginTop: '30px' }}>
      <TableGridLocal columns={columns} data={updatedData} pageSize={15} totalRowCount={moneyStore.total} />
    </div>
  );
};
