import * as yup from 'yup';

export const schema = yup.object({
  name: yup.string().optional().default(''),
  agreement: yup.string().optional().default(''),
  strategy: yup.string().optional().default(''),
  startDate: yup.string().optional().default(''),
  endDate: yup.string().optional().default(''),
  iir: yup.string().optional().default(''),
});
