import React, { useMemo, useRef } from 'react';

import { Typography, Box, FormControl } from '@mui/material';
import JoditEditor from 'jodit-react';
import { useController } from 'react-hook-form';

import { useFormContext } from '../FormProvider';

type Props = {
  name: string;
  label: string;
  disabled?: boolean;
  fullWidth?: boolean;
  margin?: 'none' | 'dense' | 'normal';
};

export const ControlledTextEditorField: React.FC<Props> = ({
  name,
  label,
  disabled,
  fullWidth = true,
  margin = 'normal',
}) => {
  const { control } = useFormContext();
  const { field, fieldState } = useController({ name, control });
  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: !!disabled,
      placeholder: label || '',
      height: 300,
      language: 'ru',
      uploader: {
        insertImageAsBase64URI: true,
      },
    }),
    [label, disabled],
  );

  return (
    <FormControl fullWidth={fullWidth} margin={margin}>
      <Box className="text-editor">
        <Typography variant="subtitle1" sx={{ mb: 1, color: '#9598a7' }}>
          {label}
        </Typography>
        <JoditEditor
          ref={editor}
          value={field.value || ''}
          config={config}
          onBlur={(newContent) => field.onChange(newContent)}
        />
        {fieldState.error && (
          <Typography variant="caption" color="error">
            {fieldState.error.message}
          </Typography>
        )}
      </Box>
    </FormControl>
  );
};
