/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';

import download from 'downloadjs';
import { useStore } from 'effector-react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import {
  downloadStrategyChart,
  publishStrategyToEva,
  updateStrategyInEva,
  freezeStrategy,
  publishStrategyToBorr,
  rebalanceStrategy,
  sendStrategyToCs,
  getApiKey,
} from '../../../api';
import { TableGrid } from '../../../components/TableGrid';
import { Button, ButtonType } from '../../../components/UIKit/Button';
import { AggregateStrategies$, aggregateStrategiesApi } from '../../../effector/aggregateStrategies';
import { modalApi } from '../../../effector/modals';
import { MODALS } from '../../../utils/types';

export const StrategyDetails: React.FC = () => {
  const { strategyId } = useParams<{ strategyId: string }>();

  const aggregateStrategiesStore = useStore(AggregateStrategies$);

  const strategy = aggregateStrategiesStore.strategies.find((s) => s.id === strategyId);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [isFrozen, setIsFrozen] = useState<boolean>(strategy?.isFrozen ?? false);
  const { enqueueSnackbar } = useSnackbar();

  const errorHandler = (result: { success: boolean; errorMessage: string }) => {
    if (result.errorMessage) {
      setError(result.errorMessage);
    } else {
      setError('');
      enqueueSnackbar('Успешно');
    }
  };

  const loadStrategies = useCallback(() => {
    aggregateStrategiesApi.get('');
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onRecalcHistory = () => {};

  const onPublishToEva = () => {
    setLoading(true);

    publishStrategyToEva(strategyId as string)
      .then(errorHandler)
      .catch((e) => {
        setError(e.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onPublishToBorr = () => {
    setLoading(true);

    publishStrategyToBorr(strategyId as string)
      .then(errorHandler)
      .catch((e) => {
        setError(e.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onUpdateInEva = () => {
    setLoading(true);

    updateStrategyInEva(strategyId as string)
      .then(errorHandler)
      .catch((e) => {
        setError(e.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDownload = () => {
    downloadStrategyChart({ id: strategyId as string }).then((result: { success: boolean; result: any }) => {
      if (result.success) {
        download(
          `data:application/octet-stream;base64,${result.result.contentBase64}`,
          `${result.result.fileName}`,
          'application/octet-stream',
        );
      }
    });
  };
  const onFreeze = () => {
    setLoading(true);

    freezeStrategy({ id: strategyId as string, freeze: !isFrozen })
      .then((result: { success: boolean; errorMessage: string }) => {
        errorHandler(result);
        setIsFrozen(result.success ? !isFrozen : isFrozen);
      })
      .catch((e) => {
        setError(e.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onRebalance = () => {
    setLoading(true);

    rebalanceStrategy(strategyId as string)
      .then(errorHandler)
      .catch((e) => {
        setError(e.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSendToCs = () => {
    setLoading(true);

    sendStrategyToCs(strategyId as string)
      .then(errorHandler)
      .catch((e) => {
        setError(e.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onGetApiKeys = () => {
    setLoading(true);

    getApiKey(strategyId as string)
      .then((result) => {
        modalApi.show({
          modalId: MODALS.API_KEYS,
          data: {
            apiKey: result?.result,
          },
        });

        errorHandler(result);
      })
      .catch((e) => {
        setError(e.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (aggregateStrategiesStore.strategies.length === 0 && strategyId) {
      loadStrategies();
    }
  }, [strategyId, aggregateStrategiesStore, loadStrategies]);

  if (!strategyId || strategyId.length === 0)
    return (
      <div className="strategies-params__item-name">
        <p>Не выбрана стратегия</p>
      </div>
    );

  if (!strategy) {
    return (
      <div className="strategies-params__item-name">
        <p>Стратегия не найдена</p>
      </div>
    );
  }

  const strategyTypeInfo: string =
    strategy.autofollow && strategy.autoconsult
      ? 'Автоследование, Автоконсультирование'
      : strategy.autofollow
      ? 'Автоследование'
      : 'Автоконсультирование';

  return (
    <>
      <div className="strategies-params__item-name">
        <p>{strategy.name}</p>
      </div>

      <div className="client-details__info">
        <div className="client-details__info-row">
          <div className="client-details__info-row_label">Идентификатор стратегии:</div>
          <div className="client-details__info-row_value">{strategy.id}</div>
        </div>
        <div className="client-details__info-row">
          <div className="client-details__info-row_label">Время создания:</div>
          <div className="client-details__info-row_value">{strategy.creationDate.toString()}</div>
        </div>
        <div className="client-details__info-row">
          <div className="client-details__info-row_label">Типы следования:</div>
          <div className="client-details__info-row_value">
            <i>{!strategyTypeInfo ? 'Не задано' : strategyTypeInfo}</i>
          </div>
        </div>
        {error && (
          <div className="client-details__info-row">
            <p className="error-message">{error}</p>
          </div>
        )}
      </div>

      <div className="table-filter">
        <div className="table-filter__title">Управление стратегией</div>

        <div className="form__group">
          <Button
            className="button button__primary button__small form__button-inline"
            type={ButtonType.button}
            onClick={onRecalcHistory}
          >
            Пересчитать историю
          </Button>
          <Button
            className="button button__primary button__small form__button-inline"
            type={ButtonType.button}
            onClick={onRebalance}
          >
            Балансировать
          </Button>
          <Button
            className="button button__primary button__small form__button-inline"
            type={ButtonType.button}
            onClick={onSendToCs}
          >
            Отправить в CS
          </Button>
          <Button
            className="button button__primary button__small form__button-inline"
            type={ButtonType.button}
            onClick={onDownload}
          >
            Скачать график доходности
          </Button>
          <Button
            className="button button__primary button__small form__button-inline"
            type={ButtonType.button}
            disabled={loading}
            onClick={onFreeze}
          >
            {isFrozen ? 'Разморозить' : 'Заморозить'}
          </Button>
          <Button
            className="button button__primary button__small form__button-inline"
            type={ButtonType.button}
            onClick={onGetApiKeys}
          >
            Получить API Keys
          </Button>
        </div>
        {isFrozen && <div className="error-message-left">Заморожена</div>}
      </div>

      <div className="table-filter">
        <div className="table-filter__title">Публиация в Ева</div>

        <div className="form__group">
          <Button
            className="button button__primary button__small form__button-inline"
            type={ButtonType.button}
            disabled={loading}
            onClick={onPublishToEva}
          >
            Опубликовать
          </Button>
          <Button
            className="button button__primary button__small form__button-inline"
            type={ButtonType.button}
            disabled={loading}
            onClick={onUpdateInEva}
          >
            Обновить
          </Button>
        </div>
        <div>Последнее обновление: {strategy.evaParams?.evaUpdatedAt ?? 'никогда'}</div>
      </div>

      <div className="table-filter">
        <div className="table-filter__title">Публиация в БОРР/КХД</div>

        <div className="form__group">
          <Button
            className="button button__primary button__small form__button-inline"
            type={ButtonType.button}
            disabled={loading}
            onClick={onPublishToBorr}
          >
            Опубликовать
          </Button>
        </div>
        <div>
          Подтверждение получено:&nbsp;
          <i>
            {strategy.borrPublication?.updatedAt ?? 'Никогда'}.&nbsp;
            {strategy.borrPublication?.success === true ? 'Успешно' : ''}
            {strategy.borrPublication?.success === false && (
              <span className="error-message">Ошибка: {strategy.borrPublication?.description}</span>
            )}
          </i>
        </div>
      </div>

      <StrategyData />

      <StrategySignalTable />
    </>
  );
};

const StrategyData: React.FC = () => {
  return (
    <div className="table-filter">
      <div className="table-filter__title">Точность следования</div>

      <div className="form__group">
        <Button
          className="button button__primary button__small form__button-inline"
          type={ButtonType.button}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClick={() => {}}
        >
          Рассчитать
        </Button>
      </div>
    </div>
  );
};

const columns = [
  {
    Header: 'Сигнал',
    accessor: 'id',
    className: 'table__column-details',
  },
  {
    Header: 'Дата',
    accessor: 'date',
    className: 'table__column-details',
  },
  {
    Header: 'Инструмент',
    accessor: 'security',
    className: 'table__column-details',
  },
  {
    Header: 'weight',
    accessor: 'weight',
    className: 'table__column-details',
  },
  {
    Header: 'Цена',
    accessor: 'price',
    className: 'table__column-details',
  },
  {
    Header: 'execprice',
    accessor: 'execPrice',
    className: 'table__column-details',
  },
  {
    Header: 'pricestd',
    accessor: 'priceStd',
    className: 'table__column-details',
  },
  {
    Header: 'diff',
    accessor: 'diff',
    className: 'table__column-details',
  },
  {
    Header: 'timedelay',
    accessor: 'timeDelay',
    className: 'table__column-details',
  },
  {
    Header: 'startPL',
    accessor: 'startPL',
    className: 'table__column-details',
  },
];

const StrategySignalTable: React.FC = () => {
  return (
    <>
      <div className="strategies-params__item-name">
        <p>Точность следования</p>
      </div>

      <div className="form__group">
        <TableGrid
          columns={columns}
          data={[]}
          sortField="datetime"
          forceReload={false}
          isRecursiveFetch={false}
          pageSize={10000}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onPageChange={() => {}}
        />
      </div>
    </>
  );
};
