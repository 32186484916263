/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useMemo } from 'react';

import { faEdit, faExchangeAlt, faPlus, faRecycle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import { useStore } from 'effector-react';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import moment from 'moment';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { getInvestboxesAdministration } from '../../../api';
import { ProtectedComponent } from '../../../components/ProtectedComponent';
import { SortType } from '../../../components/TableGrid';
import { TableGridLocal } from '../../../components/TableGridLocal';
import { Button, ButtonType } from '../../../components/UIKit/Button';
import { modalApi } from '../../../effector/modals';
import { User$ } from '../../../effector/user';
import { AggregateInvestbox } from '../../../types/aggregateInvestbox';
import { UserRoles } from '../../../types/userRoles';
import { boolToString } from '../../../utils';
import { checkPermissions, UserAction } from '../../../utils/permissions';
import { MODALS } from '../../../utils/types';
import { PAGE_ADMINISTRATION, PAGE_ADMINISTRATION_INVESTBOXES } from '../../Routes';

export const Investboxes: React.FC = () => {
  const navigate = useNavigate();
  const user = useStore(User$);

  const { data: investBoxes } = useQuery('getInvestboxesAdministration', () =>
    getInvestboxesAdministration({
      pageNumber: 0,
      pageSize: 1000,
      sortFieldName: 'name',
      sortDirection: SortType.Desk,
    }),
  );

  const onEdit = useCallback(
    (val: string) => {
      const path = `${PAGE_ADMINISTRATION}/${PAGE_ADMINISTRATION_INVESTBOXES}/${val}`;
      navigate(path);
    },
    [navigate],
  );

  const onAddInvestbox = useCallback(() => {
    modalApi.show({
      modalId: MODALS.ADD_INVESTBOX,
    });
  }, []);

  const isDeveloper = user?.role && user.role === UserRoles.Developer;

  const columns = useMemo<MRT_ColumnDef<AggregateInvestbox>[]>(() => {
    if (user) {
      const items: (MRT_ColumnDef<AggregateInvestbox> & { action: UserAction })[] = [
        {
          header: 'Название',
          accessorKey: 'title',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          enableColumnFilterModes: false,
          enableColumnActions: false,
          action: UserAction.administrationInvestbox,
        },
        {
          header: 'Создана',
          accessorKey: 'creationDate',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          enableColumnFilterModes: false,
          enableColumnActions: false,
          action: UserAction.administrationInvestbox,
          Cell: ({
            cell: {
              row: { original },
            },
          }) => {
            const timeFormatted = original?.creationDate
              ? moment(original.creationDate).format('DD.MM.YYYY HH:mm')
              : '';

            return <span>{timeFormatted}</span>;
          },
        },
        {
          header: 'Тариф',
          accessorKey: 'tariffName',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          enableColumnFilterModes: false,
          enableColumnActions: false,
          action: UserAction.administrationInvestbox,
        },
        {
          header: 'Валюта',
          accessorKey: 'currency',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          enableColumnFilterModes: false,
          enableColumnActions: false,
          action: UserAction.administrationInvestbox,
        },
        {
          header: 'Открыта',
          accessorFn: (originalRow) => boolToString(originalRow.isOpen),
          enableColumnFilter: false,
          enableColumnOrdering: false,
          enableColumnFilterModes: false,
          enableColumnActions: false,
          action: UserAction.administrationInvestbox,
          Cell: ({ cell }) => <span>{boolToString(cell?.row?.original?.isOpen)}</span>,
        },
        {
          header: '',
          accessorKey: 'edit',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          enableColumnFilterModes: false,
          enableColumnActions: false,
          enableSorting: false,
          size: 40,
          action: UserAction.administrationInvestboxAction,
          Cell: ({
            cell: {
              row: { original },
            },
          }) => {
            return (
              <span className="pointer">
                <FontAwesomeIcon
                  icon={faEdit}
                  title="Редактировать"
                  className={cs({ 'fa-disabled': isDeveloper && !original.testMode })}
                  onClick={() => onEdit(original.id)}
                />
              </span>
            );
          },
        },
        {
          header: '',
          accessorKey: 'close',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          enableColumnFilterModes: false,
          enableColumnActions: false,
          enableSorting: false,
          size: 40,
          action: UserAction.administrationInvestboxAction,
          Cell: ({
            cell: {
              row: { original },
            },
          }) => {
            return (
              <span className="pointer">
                <FontAwesomeIcon
                  icon={faExchangeAlt}
                  title="Открыть/закрыть"
                  className={cs({
                    fall: original.testMode || !isDeveloper,
                    'fa-disabled': isDeveloper && !original.testMode,
                  })}
                  onClick={() =>
                    modalApi.show({
                      modalId: MODALS.OPEN_STRATEGY,
                      data: {
                        id: original.id,
                        open: original.isOpen,
                        isInvestbox: true,
                      },
                    })
                  }
                />
              </span>
            );
          },
        },
        {
          header: '',
          accessorKey: 'remove',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          enableColumnFilterModes: false,
          enableColumnActions: false,
          enableSorting: false,
          size: 40,
          action: UserAction.administrationInvestboxAction,
          Cell: ({
            cell: {
              row: { original },
            },
          }) => {
            return (
              <span className="pointer">
                <FontAwesomeIcon
                  icon={faRecycle}
                  title="Удалить/активировать"
                  className={cs({ 'fa-disabled': isDeveloper && !original.testMode })}
                  onClick={() =>
                    modalApi.show({
                      modalId: MODALS.OPEN_STRATEGY,
                      data: {
                        id: original.id,
                        active: original.active,
                        isInvestbox: true,
                      },
                    })
                  }
                />
              </span>
            );
          },
        },
      ];

      return items.filter((item) => checkPermissions(user.role, item.action));
    }

    return [];
  }, [isDeveloper, onEdit, user]);

  const columnSorts = useMemo<MRT_SortingState>(
    () => [
      {
        desc: true,
        id: 'name',
      },
    ],
    [],
  );

  return (
    <>
      <ProtectedComponent userAction={UserAction.administrationDeveloper}>
        <div className="form__group">
          <br />
          <p>Для данного пользователя доступно редактирование только тестовых копилок.</p>
          <br />
        </div>
      </ProtectedComponent>

      <div className="form__group">
        <ProtectedComponent userAction={UserAction.administrationInvestboxAction}>
          <Button
            className="button button__primary button__small form__button-inline"
            type={ButtonType.button}
            onClick={onAddInvestbox}
          >
            <FontAwesomeIcon icon={faPlus} /> Добавить
          </Button>
        </ProtectedComponent>
      </div>

      <TableGridLocal
        columns={columns}
        columnSorts={columnSorts}
        data={investBoxes?.result.pageItems || []}
        totalRowCount={investBoxes?.result.total || 0}
      />
    </>
  );
};
