import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Stack } from '@mui/material';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { useProblemClientsStatistic, useProblemsDictionary } from './queries';
import { FilterSection } from '../../../components/FilterSection';
import { ControlledSelectField } from '../../../components/FormControlsV2/ControlledSelectField';
import { ControlledTextField } from '../../../components/FormControlsV2/ControlledTextField';
import { FormProvider } from '../../../components/FormControlsV2/FormProvider';
import { Criteria, Filter, PageParams } from '../../../components/TableGrid';
import { TableGridLocal } from '../../../components/TableGridLocal';
import { Option } from '../../../types/option';
import { ProblemClient } from '../../../types/problemClient';
import { PAGE_STATISTIC_CLIENT_DETAILS_INFO } from '../../Routes';

type FormFields = {
  number?: string;
  description: Option[];
};

export const ProblemClients: React.FC = () => {
  const [formFilters, setFormFilters] = useState<Filter[]>([]);

  const { data: problemsResult } = useProblemsDictionary();
  const { data, mutate: getProblemClients, isLoading } = useProblemClientsStatistic();

  const {
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormFields>({
    defaultValues: {
      number: '',
      description: [],
    },
  });

  const loadClients = useCallback(
    (params: PageParams) => {
      getProblemClients({
        ...params,
        filters: formFilters.concat(params.filters || []),
      });
    },
    [formFilters, getProblemClients],
  );

  const problemsOptions = useMemo(
    () => problemsResult?.result?.map((problem) => ({ value: problem.id, label: problem.name })) || [],
    [problemsResult],
  );

  const columns = useMemo<MRT_ColumnDef<ProblemClient>[]>(
    () => [
      {
        header: 'Ген. соглашение',
        accessorKey: 'number',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        Cell: ({ cell }) => {
          return (
            <Link to={`${PAGE_STATISTIC_CLIENT_DETAILS_INFO}/${cell?.row?.original?.id}`}>
              {cell?.row?.original?.number}
            </Link>
          );
        },
      },
      {
        header: 'Дата подключения',
        accessorKey: 'bindingDate',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        Cell: ({ cell }) => {
          if (!cell?.row?.original?.bindingDate) {
            return '';
          }

          return moment(cell?.row?.original?.bindingDate).format('YYYY-MM-DDTHH:mm:ss');
        },
      },
      {
        header: 'Описание',
        accessorKey: 'description',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        Cell: ({ cell }) => <span>{cell?.row?.original?.description}</span>,
      },
    ],
    [],
  );

  const columnSorts = useMemo<MRT_SortingState>(
    () => [
      {
        desc: true,
        id: 'number',
      },
    ],
    [],
  );

  const formValues = watch();

  const memoizedFormValues = useMemo(() => JSON.stringify(formValues), [formValues]);

  useEffect(() => {
    const values: FormFields = JSON.parse(memoizedFormValues || '{}');

    const filters = Object.entries(values).flatMap(([key, value]) => {
      if (typeof value === 'string' && value.trim()) {
        return {
          fieldName: key,
          filterCriteria: Criteria.Contains,
          matches: [value],
        };
      }

      if (typeof value === 'number') {
        return {
          fieldName: key,
          filterCriteria: Criteria.Equals,
          matches: [value],
        };
      }

      if (Array.isArray(value) && value.length) {
        return {
          fieldName: key,
          filterCriteria: Criteria.Equals,
          matches: value.map((item) => item.id),
        };
      }

      return [];
    });

    setFormFilters(filters);
  }, [memoizedFormValues, setFormFilters]);

  return (
    <>
      <FilterSection>
        <form>
          <FormProvider control={control} errors={errors}>
            <Stack direction="row" gap={2}>
              <ControlledTextField name="number" label="Ген. соглашение" />
              {problemsOptions && <ControlledSelectField name="strategy" label="Проблемы" options={problemsOptions} />}
            </Stack>
          </FormProvider>

          <Button variant="outlined" color="primary" size="large" fullWidth sx={{ mt: 2 }} onClick={() => reset()}>
            Сбросить
          </Button>
        </form>
      </FilterSection>
      <div style={{ marginTop: '30px' }}>
        <TableGridLocal
          serverMode
          isLoading={isLoading}
          columns={columns}
          data={data?.result?.pageStats || []}
          pageSize={25}
          columnSorts={columnSorts}
          totalRowCount={data?.result?.total || 0}
          onFetchData={loadClients}
        />
      </div>
    </>
  );
};
