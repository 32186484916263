import React from 'react';

import { TextField, FormControl } from '@mui/material';
import { Controller } from 'react-hook-form';

import { useFormContext } from '../FormProvider';

interface Props {
  name: string;
  label: string;
  type?: string;
  fullWidth?: boolean;
  margin?: 'none' | 'dense' | 'normal';
}

export const ControlledTextField: React.FC<Props> = ({
  name,
  label,
  type = 'text',
  fullWidth = true,
  margin = 'normal',
}) => {
  const { control, errors } = useFormContext();

  return (
    <FormControl fullWidth={fullWidth} margin={margin}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type={type}
            label={label}
            variant="outlined"
            error={!!errors?.[name]}
            helperText={errors?.[name]?.message}
          />
        )}
      />
    </FormControl>
  );
};
