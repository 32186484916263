import React, { useCallback, useEffect, useMemo } from 'react';

import { Button, Stack } from '@mui/material';
import { useStore } from 'effector-react';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { useBranchesDictionary, useClientsStatistic, useServicesDictionary, useStrategiesDictionary } from './queries';
import { FilterSection } from '../../../components/FilterSection';
import { ControlledSelectField } from '../../../components/FormControlsV2/ControlledSelectField';
import { ControlledTextField } from '../../../components/FormControlsV2/ControlledTextField';
import { FormProvider } from '../../../components/FormControlsV2/FormProvider';
import { Criteria, Filter, PageParams } from '../../../components/TableGrid';
import { TableGridLocal } from '../../../components/TableGridLocal';
import { getCorrectServiceType, sortColumnFn } from '../../../components/TableGridLocal/filters';
import { currencyInfo$ } from '../../../effector/currencies';
import { useDebouncedState } from '../../../hooks/useDebouncedState';
import { AccountStat } from '../../../types/accountStat';
import { Option } from '../../../types/option';
import { formatMoney, getPercentage } from '../../../utils';
import { PAGE_STATISTIC_CLIENT_DETAILS_INFO } from '../../Routes';

type FormFields = {
  strategy: Option[];
  service: Option[];
  agreement: string;
  clientId: string;
};

export const Clients: React.FC = () => {
  const [formFilters, setFormFilters] = useDebouncedState<Filter[]>([], 500);

  const currencyStore = useStore(currencyInfo$);

  const { data: branchesResult } = useBranchesDictionary();
  const { data: strategiesResult } = useStrategiesDictionary();
  const { data: servicesResult } = useServicesDictionary();
  const { data, isLoading, mutate: loadStatistic } = useClientsStatistic();

  const {
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormFields>({
    defaultValues: {
      strategy: [],
      service: [],
      agreement: '',
      clientId: '',
    },
  });

  const loadClients = useCallback(
    (params: PageParams) => {
      loadStatistic({
        ...params,
        filters: formFilters.concat(params.filters || []),
      });
    },
    [formFilters, loadStatistic],
  );

  const updatedData = useMemo(() => {
    return (data?.result?.pageStats || []).map((item) => ({
      ...item,
      currency: item.currency === 'SUR' ? 'RUB' : item.currency,
    }));
  }, [data]);

  const strategies = useMemo(() => strategiesResult?.result?.map(({ name }) => name), [strategiesResult]);
  const currencies = useMemo(() => currencyStore.map(({ code }) => (code === 'SUR' ? 'RUB' : code)), [currencyStore]);
  const branches = useMemo(() => branchesResult?.result?.map(({ name }) => name), [branchesResult]);
  const strategiesOptions = useMemo(
    () => strategiesResult?.result?.map((strategy) => ({ value: strategy.id, label: strategy.name })) || [],
    [strategiesResult],
  );
  const servicesOptions = useMemo(
    () => servicesResult?.result?.map((strategy) => ({ value: strategy.id, label: strategy.name })) || [],
    [servicesResult],
  );

  const columns = useMemo<MRT_ColumnDef<AccountStat>[]>(() => {
    const items: MRT_ColumnDef<AccountStat>[] = [
      {
        accessorKey: 'clientId',
        header: 'MasterId',
        filterVariant: 'text',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        hiddenByDefault: true,
        Cell: ({ cell }) => {
          return (
            <Link to={`${PAGE_STATISTIC_CLIENT_DETAILS_INFO}/${cell?.row?.original?.agreementId}`}>
              {cell?.row?.original?.clientId}
            </Link>
          );
        },
      },
      {
        accessorKey: 'agreement',
        header: 'Г/С',
        filterVariant: 'text',
        Cell: ({ cell }) => (
          <Link
            target="_blank"
            to={`${window.location.origin}${PAGE_STATISTIC_CLIENT_DETAILS_INFO}/${cell?.row?.original?.agreementId}`}
          >
            {cell?.row?.original?.agreement}
          </Link>
        ),
      },
      {
        header: 'Портфель',
        accessorKey: 'portfolio',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        accessorFn: (originalRow) => {
          const value = originalRow.currency === 'SUR' ? originalRow.portfolioSUR : originalRow.portfolio;
          return value;
        },
        sortingFn: (col, col2) => {
          return sortColumnFn(col.original, col2.original, 'portfolioSUR');
        },
        Cell: ({ cell }) => {
          const value =
            cell.row.original.currency === 'SUR' ? cell.row.original.portfolioSUR : cell.row.original.portfolio;

          if (!value) {
            return '';
          }

          return formatMoney(value, cell.row.original.currency);
        },
      },
      {
        header: 'В инструментах',
        accessorKey: 'positions',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        hiddenByDefault: true,
        accessorFn: (originalRow) => {
          const value = originalRow.currency === 'SUR' ? originalRow.positionsSUR : originalRow.positions;
          return value;
        },
        sortingFn: (col, col2) => {
          return sortColumnFn(col.original, col2.original, 'positionsSUR');
        },
        Cell: ({ cell }) => {
          const value =
            cell.row.original.currency === 'SUR' ? cell.row.original.positionsSUR : cell.row.original.positions;

          if (!value) {
            return '';
          }

          return formatMoney(value, cell.row.original.currency);
        },
      },
      {
        header: 'Валюта',
        accessorKey: 'currency',
        filterVariant: 'select',
        filterSelectOptions: currencies,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        hiddenByDefault: true,
        size: 50,
      },
      {
        accessorKey: 'free',
        header: 'Свободно',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        hiddenByDefault: true,
        accessorFn: (originalRow) => {
          const value = originalRow.currency === 'SUR' ? originalRow.freeSUR : originalRow.free;
          return value;
        },
        sortingFn: (col, col2) => {
          return sortColumnFn(col.original, col2.original, 'freeSUR');
        },
        Cell: ({ cell }) => {
          const value = cell.row.original.currency === 'SUR' ? cell.row.original.freeSUR : cell.row.original.free;

          if (!value) {
            return '';
          }

          return formatMoney(value, cell.row.original.currency);
        },
      },
      {
        accessorKey: 'minDiff',
        header: 'Мин. откл.',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        hiddenByDefault: true,
        size: 100,
        Cell: ({ cell }) => {
          return getPercentage(cell.row.original.minDiff);
        },
      },
      {
        accessorKey: 'withdrawFundsTotal',
        header: 'Частичный вывод',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        Cell: ({ cell }) => {
          if (!cell.row.original.withdrawFundsTotal) {
            return '';
          }

          return formatMoney(cell.row.original.withdrawFundsTotal, cell.row.original.currency);
        },
      },
      {
        accessorKey: 'bringFundsTotal',
        header: 'Дозавод',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        Cell: ({ cell }) => {
          if (!cell.row.original.bringFundsTotal) {
            return '';
          }

          return formatMoney(cell.row.original.bringFundsTotal, cell.row.original.currency);
        },
      },
      {
        accessorKey: 'deviation',
        header: 'Отклонение (%)',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        size: 100,
        Cell: ({ cell }) => {
          return getPercentage(cell.row.original.deviation);
        },
      },
      {
        accessorKey: 'refillTotal',
        header: 'Пополнения (%)',
        filterVariant: 'range',
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        size: 100,
        Cell: ({ cell }) => {
          if (!cell.row.original.refillTotal) {
            return '';
          }

          return getPercentage(cell.row.original.refillTotal);
        },
      },
      {
        accessorKey: 'pnlTotal',
        header: 'Финансовый результат',
        filterVariant: 'range',
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        Cell: ({ cell }) => {
          if (!cell.row.original.pnlTotal) {
            return '';
          }

          return getPercentage(cell.row.original.pnlTotal);
        },
      },
      {
        accessorKey: 'lifetime',
        header: 'Время на услуге (общее) д:ч:м',
        filterVariant: 'range',
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        accessorKey: 'strategy',
        header: 'Стратегия',
        filterVariant: 'multi-select',
        filterSelectOptions: strategies,
      },
      {
        accessorKey: 'service',
        accessorFn: (originalRow) => getCorrectServiceType(originalRow.service),
        header: 'Услуга',
        filterVariant: 'multi-select',
        filterSelectOptions: ['Автоследование', 'Автоконсультирование', 'Накопилка'],
        size: 150,
        Cell: ({ cell }) => getCorrectServiceType(cell.row.original.service),
      },
      {
        accessorKey: 'bindDate',
        header: 'Подключен',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        Cell: ({ cell }) => <span>{moment(cell?.row?.original?.bindDate).format('DD.MM.YYYY HH:mm')}</span>,
      },
      {
        accessorKey: 'branchName',
        header: 'Офис',
        filterVariant: 'select',
        filterSelectOptions: branches,
      },
    ];
    return items;
  }, [currencies, strategies, branches]);

  const columnVisibility = useMemo(() => {
    return columns.reduce((prev, cur) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (cur.hiddenByDefault && cur.accessorKey) {
        prev[cur.accessorKey] = false;
      }
      return prev;
    }, {} as Record<string, boolean>);
  }, [columns]);

  const columnSorts = useMemo<MRT_SortingState>(
    () => [
      {
        desc: true,
        id: 'bindDate',
      },
    ],
    [],
  );

  const formValues = watch();

  const memoizedFormValues = useMemo(() => JSON.stringify(formValues), [formValues]);

  useEffect(() => {
    const values: FormFields = JSON.parse(memoizedFormValues || '{}');

    const filters = Object.entries(values).flatMap(([key, value]) => {
      if (typeof value === 'string' && value.trim()) {
        return {
          fieldName: key,
          filterCriteria: Criteria.Contains,
          matches: [value],
        };
      }

      if (typeof value === 'number') {
        return {
          fieldName: key,
          filterCriteria: Criteria.Equals,
          matches: [value],
        };
      }

      if (Array.isArray(value) && value.length) {
        return {
          fieldName: key,
          filterCriteria: Criteria.Equals,
          matches: value.map((item) => item.id),
        };
      }

      return [];
    });

    setFormFilters(filters);
  }, [memoizedFormValues, setFormFilters]);

  return (
    <>
      <FilterSection>
        <form>
          <FormProvider control={control} errors={errors}>
            <Stack direction="row" gap={2}>
              <ControlledTextField name="agreement" label="Г/С" />
              <ControlledTextField name="clientId" label="Уникальный ключ клиента" />
            </Stack>

            <Stack direction="row" gap={2}>
              {strategiesOptions && (
                <ControlledSelectField name="strategy" label="Стратегии" options={strategiesOptions} />
              )}
              {servicesOptions && <ControlledSelectField name="service" label="Услуги" options={servicesOptions} />}
            </Stack>
          </FormProvider>

          <Button variant="outlined" color="primary" size="large" fullWidth sx={{ mt: 2 }} onClick={() => reset()}>
            Сбросить
          </Button>
        </form>
      </FilterSection>
      <div style={{ marginTop: '30px' }}>
        <TableGridLocal
          serverMode
          pageSize={25}
          columns={columns}
          data={updatedData}
          isLoading={isLoading}
          columnSorts={columnSorts}
          columnVisibility={columnVisibility}
          totalRowCount={data?.result.total || 0}
          onFetchData={loadClients}
        />
      </div>
    </>
  );
};
