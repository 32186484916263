import { useCallback } from 'react';

import { useQuery } from 'react-query';

import { getPositions, getSandboxPositions } from '../../../../api';
import { StrategyStatus } from '../../../../types/strategy';

export const useLoadPositions = (id: string, strategyStatus: StrategyStatus | undefined) => {
  const queryFn = useCallback(() => {
    if (strategyStatus === StrategyStatus.Standard || strategyStatus === StrategyStatus.Closed) {
      return getPositions(id);
    } else {
      return getSandboxPositions(id);
    }
  }, [id, strategyStatus]);

  const { data, refetch, isLoading, error } = useQuery(['positions', id, strategyStatus], queryFn, {
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
    enabled: !!id,
  });

  return { data, refetch, isLoading, error };
};
