/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { memo, useCallback, useMemo } from 'react';

import {
  faArrowDown,
  faArrowUp,
  faDollarSign,
  faDownload,
  faTimes,
  faPercent,
  faQuestion,
  faRandom,
  faComment,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import cn from 'classnames';
import download from 'downloadjs';
import { useStore } from 'effector-react';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { useLoadDeals } from './queries';
import { downloadDeals, downloadSandboxDeals } from '../../../api';
import { PageParams } from '../../../components/TableGrid';
import { TableGridLocal } from '../../../components/TableGridLocal';
import { Button, ButtonType } from '../../../components/UIKit/Button';
import { modalApi } from '../../../effector/modals';
import { Strategies$ } from '../../../effector/strategies';
import { Deal, DealType } from '../../../types/deal';
import { StrategyStatus } from '../../../types/strategy';
import { MODALS } from '../../../utils/types';

type Props = {
  strategyId: string | undefined;
};

// eslint-disable-next-line react/display-name
export const Deals: React.FC<Props> = memo(({ strategyId }) => {
  const strategies = useStore(Strategies$);
  const { enqueueSnackbar } = useSnackbar();

  const strategy = useMemo(() => strategies.find((item) => item.id === strategyId), [strategies, strategyId]);

  const { data: deals, mutate: getDeals, isLoading } = useLoadDeals(strategyId, strategy?.status);

  const loadDeals = useCallback(
    (params: PageParams) => {
      getDeals({
        ...params,
        filters: params.filters || [],
      });
    },
    [getDeals],
  );

  const onDownload = useCallback(() => {
    if (strategy?.status === StrategyStatus.SandBox || strategy?.status === StrategyStatus.Moderate) {
      downloadSandboxDeals(strategyId).then((response) => {
        const { success, result, errorMessage } = response;

        if (success) {
          download(
            `data:application/octet-stream;base64,${result.contentBase64}`,
            `${result.fileName}`,
            'application/octet-stream',
          );
        } else {
          enqueueSnackbar(errorMessage);
        }
      });
    } else {
      downloadDeals(strategyId).then((response) => {
        const { success, result, errorMessage } = response;

        if (success) {
          download(
            `data:application/octet-stream;base64,${result.contentBase64}`,
            `${result.fileName}`,
            'application/octet-stream',
          );
        } else {
          enqueueSnackbar(errorMessage);
        }
      });
    }
  }, [enqueueSnackbar, strategy?.status, strategyId]);

  const getTooltip = useCallback((type: DealType, comment: string) => {
    let tooltip = comment || '';

    switch (type) {
      case DealType.Dividend:
        tooltip = tooltip == '' ? 'Выплата дивидендов' : tooltip;
        break;
      case DealType.Coupon:
        tooltip = 'Начисление купонного дохода';
        break;
      case DealType.Varmargin:
        tooltip = 'Клиринг';
        break;
    }

    return tooltip;
  }, []);

  const columns = useMemo<MRT_ColumnDef<Deal>[]>(
    () => [
      {
        id: 'profit',
        header: '',
        accessorKey: 'weight',
        Cell: ({
          cell: {
            row: {
              original: { weight, type },
            },
          },
        }) => {
          let icon;
          let classes = weight > 0 ? 'rise' : weight === 0 ? 'neutral' : 'fall';
          const isUp = weight > 0;

          switch (type) {
            case DealType.Standard:
              icon = isUp ? faArrowUp : faArrowDown;
              break;
            case DealType.Close:
              icon = faTimes;
              break;
            case DealType.Dividend:
            case DealType.Coupon:
              icon = faDollarSign;
              classes = '';
              break;
            case DealType.Varmargin:
              icon = faPercent;
              break;
            case DealType.Replace:
              icon = faRandom;
            default:
              icon = faQuestion;
              classes = 'neutral';
              break;
          }

          return <FontAwesomeIcon className={classes} icon={icon} />;
        },
        size: 20,
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Инструмент',
        accessorKey: 'name',
        Cell: ({
          cell: {
            row: {
              original: { name, symbol, comment, type },
            },
          },
        }) => {
          const tooltip = getTooltip(type, comment);

          return (
            <Tooltip title={tooltip} arrow placement="top">
              <span>
                {name} ({symbol}){tooltip}
              </span>
            </Tooltip>
          );
        },
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Доля %',
        accessorKey: 'weight',
        size: 60,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Цена',
        accessorKey: 'price',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Время открытия',
        accessorKey: 'time',
        Cell: ({
          cell: {
            row: {
              original: { time },
            },
          },
        }) => {
          const timeFormated = moment(time).format('DD.MM.YYYY HH:mm');

          return <span>{timeFormated}</span>;
        },
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Исполнение',
        accessorKey: 'execPrice',
        Cell: ({
          cell: {
            row: {
              original: { isInProcess, execPrice, nExecPrice },
            },
          },
        }) => {
          return isInProcess ? (
            <span>в процессе</span>
          ) : (
            <span>
              {execPrice} ({nExecPrice})
            </span>
          );
        },
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Realized PnL',
        accessorKey: 'realizedPL',
        Cell: ({
          cell: {
            row: {
              original: { realizedPL },
            },
          },
        }) => {
          return (
            <span
              className={cn({
                fall: Number(realizedPL) < 0,
                rise: Number(realizedPL) > 0,
              })}
            >
              {realizedPL}
            </span>
          );
        },
        size: 60,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: '',
        accessorKey: 'comment',
        Cell: ({
          cell: {
            row: {
              original: { time },
            },
          },
        }) => {
          return (
            <span className="pointer">
              <FontAwesomeIcon
                icon={faComment}
                title="Изменить комментарий"
                onClick={() => {
                  modalApi.show({
                    modalId: MODALS.COMMENT,
                    data: {
                      strategyId,
                      date: time,
                    },
                  });
                }}
              />
            </span>
          );
        },
        size: 20,
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
    ],
    [getTooltip, strategyId],
  );

  const columnSorts = useMemo<MRT_SortingState>(
    () => [
      {
        desc: true,
        id: 'time',
      },
    ],
    [],
  );

  return (
    <>
      <Button
        className="button button__download button__margin-top button__small"
        type={ButtonType.button}
        onClick={onDownload}
      >
        <FontAwesomeIcon icon={faDownload} /> Скачать историю сделок
      </Button>
      <div style={{ marginTop: '30px' }}>
        <TableGridLocal
          serverMode
          isLoading={isLoading}
          columns={columns}
          data={deals?.result?.pageTrades || []}
          pageSize={25}
          columnSorts={columnSorts}
          totalRowCount={deals?.result?.totalTrades || 0}
          onFetchData={loadDeals}
        />
      </div>
    </>
  );
});
