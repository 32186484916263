import { PropsWithChildren } from 'react';

import { Box } from '@mui/material';

export const FilterSection = ({ children }: PropsWithChildren) => (
  <Box
    sx={{
      position: 'relative',
      marginTop: '20px',
      padding: '20px',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: (theme) => theme.palette.divider,
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        top: '-10px',
        zIndex: 10,
        backgroundColor: (theme) => theme.palette.background.paper,
        padding: '0 4px',
      }}
    >
      Фильтры
    </Box>
    {children}
  </Box>
);
