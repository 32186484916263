/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore, createApi, createEffect, forward } from 'effector';

export type TModal$State<T> = {
  login: boolean;
  changePassword: boolean;
  removePosition: boolean;
  newSignal: boolean;
  portfolio: boolean;
  removePortfolio: boolean;
  changePosition: boolean;
  removeDelayedSignal: boolean;
  addUser: boolean;
  removeUser: boolean;
  signalApproved: boolean;
  uploadTrack: boolean;
  addStrategy: boolean;
  openStrategy: boolean;
  addInvestbox: boolean;
  updateHistory: boolean;
  accountRebalance: boolean;
  accCalcRebalance: boolean;
  accountBlock: boolean;
  serviceStop: boolean;
  split: boolean;
  scheduleEdit: boolean;
  scheduleRemove: boolean;
  scheduleAdd: boolean;
  scheduleAddWeekend: boolean;
  scheduleRemoveWeekend: boolean;
  faqAddCategory: boolean;
  faqDeleteCategory: boolean;
  faqAddQuestion: boolean;
  faqDeleteQuestion: boolean;
  comment: boolean;
  apiKeys: boolean;
  data?: T;
};

const initialState: TModal$State<any> = {
  login: false,
  changePassword: false,
  removePosition: false,
  newSignal: false,
  portfolio: false,
  removePortfolio: false,
  changePosition: false,
  removeDelayedSignal: false,
  addUser: false,
  removeUser: false,
  signalApproved: false,
  uploadTrack: false,
  addStrategy: false,
  openStrategy: false,
  addInvestbox: false,
  updateHistory: false,
  accountRebalance: false,
  accCalcRebalance: false,
  accountBlock: false,
  serviceStop: false,
  split: false,
  scheduleEdit: false,
  scheduleRemove: false,
  scheduleAdd: false,
  scheduleAddWeekend: false,
  scheduleRemoveWeekend: false,
  faqAddCategory: false,
  faqDeleteCategory: false,
  faqAddQuestion: false,
  faqDeleteQuestion: false,
  comment: false,
  apiKeys: false,
};

export const Modals$ = createStore<TModal$State<any>>(initialState);

const { show, hide } = createApi(Modals$, {
  // @ts-ignore
  show: (state, { modalId, data }) => {
    const newState = { ...initialState };
    // @ts-ignore
    newState[modalId] = true;
    newState.data = data;

    return newState;
  },
  hide: () => initialState,
});

export const modalApi = {
  show: createEffect(),
  hide: createEffect(),
};

forward({
  from: modalApi.show,
  to: show,
});

forward({
  from: modalApi.hide,
  to: hide,
});
