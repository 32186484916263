export const getCorrectServiceType = (value: string) => {
  if (value === 'Накопилка' || value === 'НК') {
    return 'Накопилка';
  }

  if (value === 'Стратегия АС' || value === 'АС') {
    return 'Автоследование';
  }

  if (value === 'Стратегия АК' || value === 'АК') {
    return 'Автоконсультирование';
  }

  return value;
};

export const getShortServiceType = (value: string): number | string => {
  if (value === 'Накопилка') {
    return 3;
  }

  if (value === 'Автоследование') {
    return 1;
  }

  if (value === 'Автоконсультирование') {
    return 2;
  }

  return value;
};

export const getCorrectCurrencyCod = (value: string): string => {
  if (value === 'RUB') {
    return 'SUR';
  }

  return value;
};

export function onlyUnique(value: any, index: number, array: any) {
  return array.indexOf(value) === index;
}

export function sortColumnFn<T>(row1: T, row2: T, prop: keyof T): number {
  const first = row1[prop];
  const second = row2[prop];

  const num1 = parseFloat(first as unknown as string);
  const num2 = parseFloat(second as unknown as string);
  if (!isNaN(num1) && !isNaN(num2)) {
    return num1 - num2;
  }

  const date1 = new Date(first as string | number);
  const date2 = new Date(second as string | number);
  if (!isNaN(date1.getTime()) && !isNaN(date2.getTime())) {
    return date1.getTime() - date2.getTime();
  }

  const str1 = String(first || '').toLowerCase();
  const str2 = String(second || '').toLowerCase();
  return str1 > str2 ? 1 : str1 < str2 ? -1 : 0;
}

const convertToNumberOrString = (val: unknown): string | number => {
  if (typeof val === 'string') {
    const currency = getCorrectCurrencyCod(val);
    const shortServiceType = getShortServiceType(currency);
    const parsed = Number(shortServiceType);
    return isNaN(parsed) ? shortServiceType : parsed;
  }

  if (typeof val === 'number') {
    return val;
  }

  return String(val);
};

export const getFilterMatches = (value: unknown): (string | number)[] => {
  if (Array.isArray(value)) {
    return value.map(convertToNumberOrString);
  }

  return [convertToNumberOrString(value)];
};
