import { useMutation } from 'react-query';

import { getDeals, getSandboxDeals } from '../../../../api';
import { PageParams } from '../../../../components/TableGrid';
import { StrategyStatus } from '../../../../types/strategy';

export const useLoadDeals = (id: string | undefined, strategyStatus: StrategyStatus | undefined) => {
  return useMutation(['loadDeals', id, strategyStatus], (data: PageParams) => {
    const params = {
      strategyId: id,
      data,
    };

    if (strategyStatus === StrategyStatus.Standard || strategyStatus === StrategyStatus.Closed) {
      return getDeals(params);
    } else {
      return getSandboxDeals(params);
    }
  });
};
