import React, { useMemo } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { MRT_ColumnDef } from 'material-react-table';
import { useMutation } from 'react-query';
import * as Yup from 'yup';

import { TableGridLocal } from 'components/TableGridLocal';

import { queryExternalClients } from '../../../api';
import { Input } from '../../../components/FormControls/Input';
import { Select } from '../../../components/FormControls/Select';
import { PageParams, SortType } from '../../../components/TableGrid';
import { Button, ButtonType } from '../../../components/UIKit/Button';
import { ERRORS_TEXTS } from '../../../const/validation';
import { ServicesConfig$ } from '../../../effector/servicesConfig';
import { Strategies$ } from '../../../effector/strategies';
import { User$ } from '../../../effector/user';
import { StatusEnum } from '../../../types/clientDetails';
import { ExternalClient } from '../../../types/externalClient';
import { Option } from '../../../types/option';
import { checkPermissions, UserAction } from '../../../utils/permissions';

type FormFields = {
  contract: string;
  strategy: Option[];
  status: { id: StatusEnum; name: string }[];
};

type FormProps = {
  isLoading: boolean;
} & FormikProps<FormFields>;

type QueryParams = {
  contractMask: string;
  strategies: string[];
  types: StatusEnum[];
} & PageParams;

const validationSchema = Yup.object().shape({
  contract: Yup.string().required(ERRORS_TEXTS.required),
});

const init: FormFields = {
  contract: '',
  strategy: [],
  status: [],
};

const followTypes = [
  { id: StatusEnum.AutoFollow, name: 'AC' },
  { id: StatusEnum.Recommendations, name: 'AK' },
  { id: StatusEnum.InvestMoneybox, name: 'ИнвестКопилка' },
];

const FormTemplate: React.FC<FormProps> = (props) => {
  const strategies = useStore(Strategies$).map((s) => {
    return { id: s.id, name: s.name };
  });

  return (
    <Form className="form" noValidate>
      <div className="form-control-row">
        <Field name="contract" placeholder="Номер соглашения" component={Input} />
      </div>
      <div className="form-control-row">
        <Field
          name="strategy"
          placeholder="название стратегии"
          multiple={true}
          options={strategies}
          component={Select}
        />
      </div>
      <div className="form-control-row">
        <Field name="status" placeholder="тип следования" multiple={true} options={followTypes} component={Select} />
      </div>
      <div className="form-control-row">
        <Button className="button__primary button__large" disabled={props.isLoading} type={ButtonType.submit}>
          Проверить
        </Button>
      </div>
    </Form>
  );
};

export const Clients: React.FC = () => {
  const user = useStore(User$);

  const config = useStore(ServicesConfig$);

  const { data: clients, mutate, isLoading } = useMutation('externalClients', queryExternalClients);

  const columns = useMemo<MRT_ColumnDef<ExternalClient>[]>(() => {
    if (!user) {
      return [];
    }

    const items: (MRT_ColumnDef<ExternalClient> & { action: UserAction })[] = [
      {
        header: 'Номер Г/С',
        accessorKey: 'contract',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        action: UserAction.administrationClients,
      },
      {
        header: 'Фамилия',
        accessorKey: 'lastName',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        action: UserAction.administrationClients,
      },
      {
        header: 'Имя',
        accessorKey: 'firstName',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        action: UserAction.administrationClients,
      },
      {
        header: 'Отчество',
        accessorKey: 'midName',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        action: UserAction.administrationClients,
      },
      {
        header: 'Счет',
        accessorKey: 'tradeCode',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        action: UserAction.administrationClients,
      },
      {
        header: 'Счет Futures',
        accessorKey: 'futCode',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        action: UserAction.administrationClients,
      },
      {
        header: 'Квал',
        accessorKey: 'isQual',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        action: UserAction.administrationClients,
      },
      {
        header: 'Риск-профиль',
        accessorKey: 'riskName',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        action: UserAction.administrationClients,
      },
      {
        header: 'Обновлено',
        accessorKey: 'eventTime',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        action: UserAction.administrationClients,
      },
      {
        header: 'Стратегия',
        accessorKey: 'strategyName',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        action: UserAction.administrationClients,
      },
      {
        header: 'Тип',
        accessorKey: 'strategyType',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        action: UserAction.administrationClients,
      },
      {
        header: 'Статус',
        accessorKey: 'strategyStatus',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        action: UserAction.administrationClients,
      },
      {
        header: 'Время',
        accessorKey: 'changeTime',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        action: UserAction.administrationClients,
      },
    ];

    const filteredColumns = items.filter((item) => checkPermissions(user.role, item.action as UserAction));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return filteredColumns.map(({ action, ...rest }) => rest);
  }, [user]);

  const onSubmit = (values: FormFields) => {
    const data: QueryParams = {
      pageNumber: 1,
      pageSize: 20,
      sortFieldName: 'contract',
      sortDirection: SortType.Ask,
      contractMask: values.contract,
      strategies: values.strategy?.map((s) => s.id) ?? [],
      types: values.status?.map((s) => s.id) ?? [],
    };

    mutate(data);
  };

  if (!config?.isEsDb)
    return (
      <div className="client-params__item-name">
        <p className="error-message-left">Просмотр клиентов недоступен на сервере.</p>
        <p>Для подключения измените настройки подключения к БД Сервиса внешних данных и адрес сервиса на сервере.</p>
      </div>
    );

  const result = clients?.result || [];
  const total = result.length;

  return (
    <>
      <div className="table-filter">
        <div className="table-filter__title">Фильтры клиента</div>
        <Formik initialValues={init} validationSchema={validationSchema} onSubmit={onSubmit}>
          {(props) =>
            React.createElement(
              FormTemplate,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              {
                ...props,
                isLoading,
              },
            )
          }
        </Formik>
      </div>
      <div style={{ marginTop: '30px' }}>
        <TableGridLocal columns={columns} data={result} pageSize={15} totalRowCount={total} />
      </div>
    </>
  );
};
