/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import cn from 'classnames';
import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { useQueryClient } from 'react-query';

import { Params } from './Params';
import { PositionList } from './PositionList';
import { ToolRool } from './ToolRool';
import { validateForm } from './validation';
import { getComment, newPortfolioSignal } from '../../../api';
import { modalApi, Modals$, TModal$State } from '../../../effector/modals';
import { portfolioApi } from '../../../effector/portfolio';
import { Search$, searchApi } from '../../../effector/search';
import { signalsApi } from '../../../effector/signal';
import { Strategies$ } from '../../../effector/strategies';
import { User$ } from '../../../effector/user';
import { useLoadPositions } from '../../../screens/Strategy/Positions/queries';
import { Position } from '../../../types/position';
import { RecalculationMode, StrategyStatus } from '../../../types/strategy';
import { formatMoney, roundNumber } from '../../../utils';
import { MODALS, Nullable } from '../../../utils/types';
import { Input } from '../../FormControls/Input';
import { Radio } from '../../FormControls/Radio';
import { TextArea } from '../../FormControls/TextArea';
import { Search } from '../../Search';
import { Button, ButtonType } from '../../UIKit/Button';

const signalTypes = [
  {
    id: 1,
    name: 'Покупка',
  },
  {
    id: 2,
    name: 'Продажа',
  },
  {
    id: 3,
    name: 'Закрытие',
  },
];

const executionTypes = [
  {
    id: 0,
    name: 'Немедленное исполнение',
  },
  {
    id: 1,
    name: 'С предварительной активацией',
  },
];

type FormFields = {
  price?: number | string;
  size: number | string;
  stoploss: number | string;
  takeprofit: number | string;
  type: number | string;
  executionType: number | string;
  description: string;
};

type FormProps = {
  error: string;
  tabId: number;
  isDisabled: boolean;
  editablePosition: Nullable<Position>;
  disableFields: number[];
  listSignals: MarketSignal[];
  setListSignals: (signals: MarketSignal[]) => void;
  setSignalType: (signalType: number) => void;
  setWeight: (weight: number) => void;
  setComment: (comment: string) => void;
  onAddSignal: (values: FormFields) => void;
  onUpdatePortfolioPositionSignal: (values: FormFields) => void;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({
  values,
  errors,
  setFieldValue,
  tabId,
  isDisabled,
  editablePosition,
  disableFields,
  error,
  onAddSignal,
  setSignalType,
  setWeight,
  setComment,
  resetForm,
  onUpdatePortfolioPositionSignal,
}) => {
  const searchStore = useStore(Search$);

  useEffect(() => {
    const weight = Number(values.size);

    weight ? setWeight(weight) : setWeight(0);
  }, [values.size]);

  useEffect(() => {
    if (!editablePosition) {
      setFieldValue('type', 1);
    }
  }, [searchStore?.details?.key, tabId]);

  useEffect(() => {
    setFieldValue('price', '');
    setFieldValue('executionType', 0);
  }, [tabId]);

  useEffect(() => {
    setSignalType(values.type as number);
    if (values.type === 3) {
      setFieldValue('size', '');
      setFieldValue('stoploss', '');
      setFieldValue('takeprofit', '');
    }
  }, [values.type]);

  useEffect(() => {
    setComment(values.description);
  }, [values.description]);

  const disable = useMemo(() => Object.keys(errors).length > 0 || isDisabled, [errors, isDisabled]);

  const onAddSignalsToList = useCallback(() => {
    onAddSignal(values);
    resetForm();
  }, [values, onAddSignal, resetForm]);

  return (
    <Form className="form form-signal__content" noValidate>
      <div className="form-control-group">
        <Field
          type="text"
          name="size"
          placeholder="Объем позиции, %"
          disabled={isDisabled || values.type === 3}
          component={Input}
        />
        <Field
          type="text"
          name="stoploss"
          placeholder="Уровень Stop Loss"
          disabled={isDisabled || values.type === 3}
          component={Input}
        />
      </div>

      <div className="form-control-group">
        <Field
          type="text"
          name="takeprofit"
          placeholder="Уровень Take Profit"
          disabled={isDisabled || values.type === 3}
          component={Input}
        />

        {tabId === tabs[1].id && (
          <Field type="text" name="price" placeholder="Цена исполнения" disabled={isDisabled} component={Input} />
        )}
      </div>

      <div className="form-signal__signal">
        <div className="form-signal__signal-label">Тип сигнала:</div>
        <div className="form-signal__signal-wrapper form-signal__signal-types">
          {signalTypes.map((item) => {
            if (tabId === tabs[3].id && item.id === 3) {
              return null;
            }

            return (
              <Field
                key={item.id}
                name="type"
                text={item.name}
                value={item.id}
                checked={values.type === item.id}
                className="form-signal__signal-type"
                onChange={() => setFieldValue('type', item.id)}
                disabled={isDisabled || disableFields.includes(item.id)}
                component={Radio}
              />
            );
          })}
        </div>
      </div>

      {tabId === tabs[1].id && (
        <div className="form-signal__signal">
          <div className="form-signal__signal-label">Исполнение:</div>
          <div className="form-signal__signal-wrapper">
            {executionTypes.map((item) => (
              <Field
                key={item.id}
                name="executionType"
                text={item.name}
                value={item.id}
                checked={values.executionType === item.id}
                onChange={() => setFieldValue('executionType', item.id)}
                disabled={isDisabled || disableFields.includes(item.id)}
                component={Radio}
              />
            ))}
          </div>
        </div>
      )}

      <div className="form__group">
        <Field
          name="description"
          rows={5}
          placeholder="Комментарий к сигналу"
          disabled={isDisabled}
          component={TextArea}
        />
      </div>

      {error && <p className="error-message">{error}</p>}

      {(tabId === tabs[0].id || tabId === tabs[1].id) && (
        <div className="form-signal__button-wrapper">
          <Button className="button__primary button__large" disabled={disable} type={ButtonType.submit}>
            Сформировать сигнал
          </Button>
        </div>
      )}

      {tabId === tabs[2].id && (
        <div className="form-signal__button-wrapper">
          <Button
            onClick={onAddSignalsToList}
            className="button__primary button__large"
            disabled={isDisabled}
            type={ButtonType.button}
          >
            Добавить в список
          </Button>
        </div>
      )}

      {tabId === tabs[3].id && (
        <div className="form-signal__buttons-wrapper">
          <Button
            className="button__primary button__large"
            disabled={isDisabled || editablePosition != null}
            type={ButtonType.submit}
          >
            Добавить в список
          </Button>

          <Button
            onClick={() => onUpdatePortfolioPositionSignal(values)}
            className="button__primary button__large"
            disabled={isDisabled || !editablePosition}
            type={ButtonType.button}
          >
            Внести изменения
          </Button>
        </div>
      )}
    </Form>
  );
};

const tabs = [
  {
    id: 1,
    name: 'Рыночный сигнал',
  },
  {
    id: 2,
    name: 'Отложенный сигнал',
  },
  {
    id: 3,
    name: 'Пакетная заявка',
  },
  {
    id: 4,
    name: 'Портфельный сигнал',
  },
];

export type MarketSignal = {
  weight: number;
  strategyId: Nullable<string>;
  symbol: Nullable<string>;
  classCode: Nullable<string>;
  board: Nullable<string>;
  comment: Nullable<string>;
  stopLoss?: number;
  takeProfit?: number;
  typeSignal?: number;
};

export const AddSignalForm: React.FC = () => {
  const [isSetData, setIsSetData] = useState(false);
  const [error, setError] = useState('');
  const [active, setTab] = useState(tabs[0]);
  const [signalType, setSignalType] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [weight, setWeight] = useState<number>(0);
  const [clearSearch, setClearSearch] = useState<boolean>(false);
  const [listSignals, setListSignals] = useState<MarketSignal[]>([]);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [editablePosition, setEditablePosition] = useState<Nullable<Position>>(null);
  const [forceSearchValue, setForceSearchValue] = useState<string>('');
  const [positions, setPositions] = useState<Position[]>([]);
  const [initialComment, setInitialComment] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const resetFunction = React.useRef(null);

  const user = useStore(User$);
  const searchStore = useStore(Search$);
  const modalsState = useStore<TModal$State<{ strategyId: string }>>(Modals$);
  const strategies = useStore(Strategies$);

  const queryClient = useQueryClient();

  const strategyId = modalsState?.data?.strategyId;
  const details = searchStore?.details;

  const strategy = useMemo(() => strategies.find((item) => item.id === strategyId), [strategies, strategyId]);

  const { data } = useLoadPositions(strategyId as string, strategy?.status);

  useEffect(() => {
    if (!isSetData) {
      setPositions(data?.result || []);
      setIsSetData(true);
    }
  }, [isSetData, data]);

  useEffect(() => {
    return () => {
      searchApi.clearDetails('');
    };
  }, []);

  useEffect(() => {
    if (!details) {
      if (resetFunction.current) {
        // @ts-ignore
        resetFunction.current();
      }
      setEditablePosition(null);
    }
  }, [details]);

  useEffect(() => {
    forceClearSearch();
  }, [active]);

  const loadComment = useCallback(() => {
    getComment(strategyId as string, moment().format('YYYY-MM-DD'))
      .then((response) => {
        if (response.success && response.result.comment) {
          setInitialComment(response.result.comment);
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }, [strategyId]);

  useEffect(() => {
    loadComment();
  }, []);

  const strikePrice = useMemo(() => {
    let value = '';

    if (details && details.quotation && details.price) {
      if (signalType === 1) {
        const q = details.quotation * (1 + (details.execCoef * weight) / 100);
        const p = details.price * (1 + (details.execCoef * weight) / 100);

        value = `(${roundNumber(q, details.priceDigits)}) ${formatMoney(p, details.currency, details.priceDigits)}`;
      } else if (signalType === 2) {
        const q = details.quotation * (1 - (details.execCoef * weight) / 100);
        const p = details.price * (1 - (details.execCoef * weight) / 100);

        value = `(${roundNumber(q, details.priceDigits)}) ${formatMoney(p, details.currency, details.priceDigits)}`;
      } else if (signalType === 3) {
        const q = details.quotation * (1 - (details.execCoef * details.currentWeight) / 100);
        const p = details.price * (1 - (details.execCoef * details.currentWeight) / 100);

        value = `(${roundNumber(q, details.priceDigits)}) ${formatMoney(p, details.currency, details.priceDigits)}`;
      }
    }
    return value;
  }, [details, signalType, weight]);

  const params = useMemo(() => {
    const par = {
      title: 'параметры инструмента',
      items: [
        {
          name: 'тикер',
          // @ts-ignore
          value: searchStore?.details?.key,
          className: '',
        },
        {
          name: 'код класса',
          // @ts-ignore
          value: searchStore?.details?.classCode,
        },
        {
          name: 'текущая стоимость',
          // @ts-ignore
          value:
            searchStore?.details?.quotation != null
              ? `${searchStore.details.quotation} (${formatMoney(
                  searchStore?.details?.price,
                  searchStore?.details?.currency,
                  searchStore?.details?.priceDigits,
                )})`
              : formatMoney(
                  searchStore?.details?.price,
                  searchStore?.details?.currency,
                  searchStore?.details?.priceDigits,
                ),
        },
        {
          name: 'размер лота',
          // @ts-ignore
          value: searchStore?.details?.lotSize ? `${searchStore?.details?.lotSize} шт` : '',
        },
        {
          name: 'шорт',
          // @ts-ignore
          value: searchStore?.details?.shortAllowed ? 'доступен' : 'не доступен',
          className: searchStore?.details?.shortAllowed ? 'rise' : 'fall',
        },
      ],
    };

    if (details?.execCoef) {
      par.items.splice(3, 0, {
        name: 'цена исполнения при текущей ликвидности',
        value: strikePrice,
      });
    }

    return par;
  }, [searchStore, details]);

  const params1 = useMemo(
    () => ({
      title: 'параметры сигнала',
      items: [
        {
          name: 'минимальный объем',
          value: `${searchStore?.details?.minWeight}%`,
          className: 'rise',
        },
        {
          name: 'максимальный объем',
          value: `${searchStore?.details?.maxWeight}%`,
          className: 'fall',
        },
        {
          name: 'текущая позиция',
          value: `${searchStore?.details?.currentWeight}%`,
          className: 'rise',
        },
      ],
    }),
    [searchStore],
  );

  const saleIsDisabled = useMemo(() => !details?.shortAllowed && details?.currentWeight === 0, [details]);

  const closeIsDisabled = useMemo(() => details?.currentWeight === 0, [details]);

  const onRemoveSignals = useCallback(
    (signal: MarketSignal) => {
      const signals = listSignals.filter((item) => item.symbol !== signal.symbol);
      setListSignals(signals);
    },
    [listSignals, setListSignals],
  );

  const onAddPositionToPortfolioSignal = useCallback(
    (values: FormFields) => {
      if (details) {
        const params = createMarketSignal(values);
        const position: Position = {
          securityKey: `${details.symbol} ${details.classCode} QUIK`,
          symbol: params.symbol as string,
          name: details.name,
          comment,
          weight: params.weight,
          openPrice: 0,
          openTime: '',
          classCode: params.classCode as string,
          currentWeight: params.weight,
        };

        setHasChanges(true);
        setPositions([...positions, position]);
      }

      setLoading(false);
      forceClearSearch();
    },
    [positions, comment, details, setPositions, setHasChanges],
  );

  const onEditPortfolioPositionSignal = useCallback(
    (name: string) => {
      setForceSearchValue(name);
      const position = positions.find((item) => item.name === name);
      setEditablePosition(position);
      onSearchClick(position?.securityKey as string);
    },
    [positions, setForceSearchValue, setEditablePosition],
  );

  const onUpdatePortfolioPositionSignal = useCallback(
    (values: FormFields) => {
      const marketSignal = createMarketSignal(values);
      const updatedPosition: Position = {
        ...(editablePosition as Position),
        weight: marketSignal.weight,
        stopLoss: marketSignal.stopLoss,
        takeProfit: marketSignal.takeProfit,
        currentWeight: marketSignal.weight,
        comment,
      };

      const filteredPositions = positions.filter((item) => item.securityKey !== updatedPosition.securityKey);
      setPositions([...filteredPositions, updatedPosition]);
      setHasChanges(true);
      forceClearSearch();
    },
    [positions, comment, editablePosition, setHasChanges, setPositions],
  );

  const onRemovePortfolioPositionSignal = useCallback(
    (position: Position) => {
      setHasChanges(true);
      setPositions(positions.filter((item) => item.securityKey !== position.securityKey));
    },
    [positions, setPositions, setHasChanges],
  );

  const onSavePortfolioChanges = useCallback(() => {
    setLoading(true);
    const updatedPositions = positions.map(({ securityKey, weight }) => ({
      securityKey,
      weight,
      comment,
    }));

    const data = {
      stratId: strategy?.id,
      managerId: user?.id,
      positions: updatedPositions,
    };

    newPortfolioSignal(data)
      .then(errorHandler)
      .then(onSuccess)
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
      });
  }, [strategy, user, positions]);

  const initialValues: FormFields = useMemo(() => {
    const weightProp =
      strategy?.recalcMode === RecalculationMode.WeightedAverageWithRecalculation ||
      strategy?.recalcMode === RecalculationMode.WeightedAverageWithoutRecalculation
        ? 'weight'
        : 'currentWeight';
    return {
      price: '',
      size: editablePosition
        ? Number(editablePosition[weightProp]) > 0
          ? editablePosition[weightProp]?.toString()
          : (editablePosition[weightProp] * -1).toString()
        : '',
      stoploss: editablePosition ? editablePosition.stopLoss?.toString() ?? '' : '',
      takeprofit: editablePosition ? editablePosition.takeProfit?.toString() ?? '' : '',
      type: editablePosition ? (Number(editablePosition[weightProp]) > 0 ? 1 : 2) : '',
      executionType: 0,
      description: initialComment,
    };
  }, [editablePosition, initialComment, strategy]);

  const forceClearSearch = useCallback(() => {
    setClearSearch(true);
    setTimeout(() => setClearSearch(false), 300);
  }, [setClearSearch]);

  // @ts-ignore
  const errorHandler = (result) => {
    // @ts-ignore
    if (result.errorMessage) {
      // @ts-ignore
      setError(result.errorMessage);
    } else {
      modalApi.hide('');
    }

    return result;
  };

  // @ts-ignore
  const onSuccess = (result) => {
    if (!result.errorMessage) {
      if (strategy?.status === StrategyStatus.Standard || strategy?.status === StrategyStatus.Closed) {
        portfolioApi.get(strategyId as string);
      } else {
        portfolioApi.getSandbox(strategyId as string);
      }

      queryClient.invalidateQueries(['positions', strategyId, strategy?.status]);

      signalsApi.checkSignal(strategyId as string);
      modalApi.show({
        modalId: MODALS.SIGNAL_APPROVED,
      });
    }

    return result;
  };

  const createMarketSignal = (values: FormFields) => {
    let weight = Number(values.size);

    if (values.type === 2) {
      weight = -1 * Number(values.size);
    } else if (values.type === 3) {
      weight = 0;
    }

    const params: MarketSignal = {
      strategyId,
      symbol: searchStore?.details?.symbol,
      classCode: searchStore?.details?.classCode,
      board: searchStore?.details?.board,
      weight: weight,
      comment,
    };

    if (Number(values.stoploss)) {
      // @ts-ignore
      params.stopLoss = Number(values.stoploss);
    }

    if (Number(values.takeprofit)) {
      // @ts-ignore
      params.takeProfit = Number(values.takeprofit);
    }

    if (Number(values.type)) {
      // @ts-ignore
      params.typeSignal = Number(values.type);
    }

    setInitialComment(comment);
    return params;
  };
  // @ts-ignore
  const sendMarketSignal = (values) => {
    const params = createMarketSignal(values);

    if (strategy?.status === StrategyStatus.Standard || strategy?.status === StrategyStatus.Closed) {
      signalsApi
        // @ts-ignore
        .newMarket(params)
        .then(errorHandler)
        .then(onSuccess)
        .catch(errorHandler)
        .finally(() => {
          setLoading(false);
        });
    } else {
      signalsApi
        // @ts-ignore
        .newMarketSandbox(params)
        .then(errorHandler)
        .then(onSuccess)
        .catch(errorHandler)
        .finally(() => {
          setLoading(false);
        });
    }
  };
  // @ts-ignore
  const sendDelayedSignal = (values) => {
    let weight = Number(values.size);

    if (values.type === 2) {
      weight = -1 * Number(values.size);
    } else if (values.type === 3) {
      weight = 0;
    }

    const params = {
      strategyId,
      symbol: searchStore?.details?.symbol,
      classCode: searchStore?.details?.classCode,
      board: searchStore?.details?.board,
      weight: weight,
      type: values.executionType,
      comment,
      execQuotation: Number(values.price),
    };

    if (Number(values.stoploss)) {
      // @ts-ignore
      params.stopLoss = Number(values.stoploss);
    }

    if (Number(values.takeprofit)) {
      // @ts-ignore
      params.takeProfit = Number(values.takeprofit);
    }

    if (Number(values.type)) {
      // @ts-ignore
      params.typeSignal = Number(values.type);
    }

    if (strategy?.status === StrategyStatus.Standard || strategy?.status === StrategyStatus.Closed) {
      signalsApi
        .newDelayed(params)
        .then(errorHandler)
        .then(onSuccess)
        .catch(errorHandler)
        .finally(() => {
          setLoading(false);
        });
    } else {
      signalsApi
        .newDelayedSandbox(params)
        .then(errorHandler)
        .then(onSuccess)
        .catch(errorHandler)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onSearchClick = (securityKey: string) => {
    searchApi.getPosition({ securityKey, strategyId });
    searchApi.clearSuggestion('');
  };

  const onAddSignal = (values: FormFields) => {
    const signal = createMarketSignal(values);
    const found = listSignals.find((item) => item.symbol === signal.symbol);

    if (!found) {
      const signals = [...listSignals, signal];
      setListSignals(signals);
      forceClearSearch();
    } else {
      setError('Данный инструмент уже добавлен в список');
    }
  };

  const sendMultiple = () => {
    const data = listSignals.map((item) => ({ ...item, comment }));
    setLoading(true);
    signalsApi
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .newMultipleMarketSignal(data)
      .then(errorHandler)
      .then(onSuccess)
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = (values: FormFields) => {
    setLoading(true);
    if (active.id === 1) {
      sendMarketSignal(values);
    } else if (active.id === 4) {
      onAddPositionToPortfolioSignal(values);
    } else {
      sendDelayedSignal(values);
    }
  };

  const validation = (values: FormFields) => validateForm<FormFields>(values, active.id);

  const disableFields: number[] = [];

  if (saleIsDisabled) {
    disableFields.push(2);
  }

  if (closeIsDisabled) {
    disableFields.push(3);
  }

  return (
    <div className="form-content">
      <div className="form-signal__tool-list">
        {active.id === tabs[2].id && (
          <ToolRool items={listSignals} onRemove={onRemoveSignals} disabled={loading} onSubmit={sendMultiple} />
        )}
        {active.id === tabs[3].id && (
          <PositionList
            disabled={!hasChanges}
            onSubmit={onSavePortfolioChanges}
            positions={positions}
            onEditPortfolioPositionSignal={onEditPortfolioPositionSignal}
            onRemovePortfolioPositionSignal={onRemovePortfolioPositionSignal}
            recalculationMode={strategy?.recalcMode}
          />
        )}
      </div>
      <div className="form-signal">
        <div className="form-signal__tabs">
          {tabs.map((item) => (
            <div
              key={item.id}
              onClick={() => setTab(item)}
              className={cn('form-signal__tab', {
                'form-signal__tab-active': active.id === item.id,
              })}
            >
              {item.name}
            </div>
          ))}
        </div>
        <div className="form-signal__search">
          <Search
            strategyId={strategyId}
            forceSearchValue={forceSearchValue}
            forceClear={clearSearch}
            onSearchClick={onSearchClick}
          />
        </div>
        {details && <Params params={[params, params1]} />}

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validate={validation}
          validateOnChange
          validateOnBlur
          onSubmit={onSubmit}
        >
          {(props) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            resetFunction.current = props.resetForm;

            return React.createElement(FormTemplate, {
              ...props,
              error,
              tabId: active.id,
              isDisabled: loading || !details,
              editablePosition,
              disableFields,
              listSignals,
              setListSignals,
              setSignalType,
              setWeight,
              setComment,
              onAddSignal,
              onUpdatePortfolioPositionSignal,
            });
          }}
        </Formik>
      </div>
    </div>
  );
};
