import { useCallback, useMemo, useState } from 'react';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import { Button } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import { useQuery } from 'react-query';

import { BannerDialog } from './BannerDialog';
import { RemoveBannerDialog } from './RemoveBannerDialog';
import { getBanners } from '../../api';
import { SortType } from '../../components/TableGrid';
import { TableGridLocal } from '../../components/TableGridLocal';
import { Banner } from '../../types/banner';

export function Banners() {
  const [open, setOpen] = useState(false);
  const [banner, setBanner] = useState<Banner | null>(null);
  const [openDelete, setOpenDelete] = useState(false);

  const { data: banners, refetch } = useQuery('getBanners', () =>
    getBanners({
      pageNumber: 0,
      pageSize: 1000,
      sortFieldName: 'name',
      sortDirection: SortType.Desk,
    }),
  );

  const columns = useMemo<MRT_ColumnDef<Banner>[]>(
    () => [
      {
        header: 'Заголовок',
        accessorKey: 'title',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Подзаголовок',
        accessorKey: 'subtitle',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Текст кнопки',
        accessorKey: 'buttonText',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Ссылка',
        accessorKey: 'url',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'UTM-метка',
        accessorKey: 'utm',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Статус',
        accessorKey: 'active',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        size: 40,
        Cell: ({
          cell: {
            row: {
              original: { active },
            },
          },
        }) => {
          const name = active ? 'Активный' : 'Неактивный';

          return <span>{name}</span>;
        },
      },
      {
        header: '',
        accessorKey: 'id',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        size: 40,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <EditNoteRoundedIcon
              sx={{ cursor: 'pointer', marginTop: 0.5 }}
              onClick={() => {
                setOpen(true);
                setBanner(original);
              }}
            />
          );
        },
      },
      {
        header: '',
        accessorKey: 'remove',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        size: 40,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <SwapHorizRoundedIcon
              sx={{ cursor: 'pointer', marginTop: 0.5 }}
              className="fall"
              onClick={() => {
                setOpenDelete(true);
                setBanner(original);
              }}
            />
          );
        },
      },
    ],
    [],
  );

  const handleUpdate = useCallback(() => {
    setOpen(false);
    setOpenDelete(false);
    setBanner(null);
    refetch();
  }, [refetch]);

  const handleCloseDialog = useCallback(() => {
    setOpen(false);
    setOpenDelete(false);
    setBanner(null);
  }, []);

  return (
    <>
      <Button variant="contained" color="primary" startIcon={<AddRoundedIcon />} onClick={() => setOpen(true)}>
        Добавить
      </Button>
      {open && <BannerDialog banner={banner} onClose={handleCloseDialog} onUpdate={handleUpdate} />}
      {openDelete && banner && (
        <RemoveBannerDialog banner={banner} onClose={handleCloseDialog} onUpdate={handleUpdate} />
      )}
      <div style={{ marginTop: '30px' }}>
        <TableGridLocal
          columns={columns}
          data={banners?.result?.pageItems || []}
          pageSize={15}
          totalRowCount={banners?.result?.total || 0}
        />
      </div>
    </>
  );
}
