/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useStore } from 'effector-react';
import { MRT_ColumnDef } from 'material-react-table';
import { useParams } from 'react-router-dom';

import { sandboxDeleteTrack, sandboxTrackStatus } from '../../../api';
import { ProtectedComponent } from '../../../components/ProtectedComponent';
import { TableGridLocal } from '../../../components/TableGridLocal';
import { Button, ButtonType } from '../../../components/UIKit/Button';
import { modalApi } from '../../../effector/modals';
import { Strategies$, strategiesApi } from '../../../effector/strategies';
import { RequestModerate, RequestModerateAction } from '../../../types/requestModerate';
import { StrategyStatus } from '../../../types/strategy';
import { UserAction } from '../../../utils/permissions';
import { MODALS } from '../../../utils/types';

export const Track: React.FC = () => {
  const { id: strategyId } = useParams<{ id: string }>();
  const [error, setError] = useState<string>('');
  const [actionError, setActionError] = useState<string>('');
  const [uploading, setUploading] = useState<number>(0);
  const [loadingTrack, setLoadingTrack] = useState<boolean>(false);

  const strategies = useStore(Strategies$);

  const strategy = useMemo(() => strategies.find((item) => item.id === strategyId), [strategyId, strategies]);

  const strategyStatus = useMemo(() => {
    if (uploading) {
      return `Загрузка ${uploading}%`;
    }

    if (strategy?.status === StrategyStatus.Moderate) {
      return 'На модерации';
    }

    if (strategy?.status === StrategyStatus.SandBox) {
      return 'Песочница';
    }

    if (strategy?.status === StrategyStatus.Closed) {
      return 'К Запуску';
    }

    if (strategy?.status === StrategyStatus.Standard) {
      return 'Запущена';
    }

    return '';
  }, [strategy?.status, uploading]);

  const openUploadTrackForm = useCallback(() => {
    modalApi.show({
      modalId: MODALS.UPLOAD_TRACK,
      data: { strategyId: strategyId as string },
    });
  }, [strategyId]);

  const deleteTrack = useCallback(() => {
    setActionError('');
    sandboxDeleteTrack(strategyId as string)
      .then((result) => {
        const { success, errorMessage } = result;

        if (success) {
        } else {
          setActionError(errorMessage);
        }
      })
      .catch(() => {
        setActionError('Произошла непредвиденная ошибка');
      });
  }, [setActionError, strategyId]);

  const requestModerate = useCallback(
    (action: RequestModerateAction) => {
      const data: RequestModerate = {
        strategyId: strategyId as string,
        action,
      };
      setActionError('');
      strategiesApi
        .requestModerate(data)
        .then((result) => {
          // @ts-ignore
          const { success, errorMessage } = result;

          if (!success) {
            setActionError(errorMessage);
          }
        })
        .catch(() => {
          setActionError('Произошла непредвиденная ошибка');
        });
    },
    [strategyId],
  );

  const moderate = useCallback(
    (action: RequestModerateAction) => {
      const data: RequestModerate = {
        strategyId: strategyId as string,
        action,
      };
      setActionError('');
      strategiesApi
        .moderate(data)
        .then((result) => {
          // @ts-ignore
          const { success, errorMessage } = result;

          if (!success) {
            setActionError(errorMessage);
          }
        })
        .catch(() => {
          setActionError('Произошла непредвиденная ошибка');
        });
    },
    [strategyId],
  );

  const toSandbox = useCallback(() => {
    const data = {
      strategyId: strategyId as string,
    };
    setActionError('');
    strategiesApi
      .toSandbox(data)
      .then((result) => {
        // @ts-ignore
        const { success, errorMessage } = result;

        if (!success) {
          setActionError(errorMessage);
        }
      })
      .catch(() => {
        setActionError('Произошла непредвиденная ошибка');
      });
  }, [strategyId]);

  const data = useMemo(
    () => [
      {
        status: strategyStatus,
        error: error,
        action: '',
      },
    ],
    [strategyStatus, error],
  );

  const columns = useMemo<
    MRT_ColumnDef<{
      status: string;
      error: string;
      action: string;
    }>[]
  >(
    () => [
      {
        header: 'Текущий статус',
        accessorKey: 'status',
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Ошибки',
        accessorKey: 'error',
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Доступные действия',
        accessorKey: 'action',
        size: 200,
        Cell: () => {
          return (
            <div>
              {strategy?.status === StrategyStatus.SandBox && (
                <>
                  <ProtectedComponent userAction={UserAction.sandboxTrackAdd}>
                    {!loadingTrack && (
                      <div>
                        <Button
                          className="button button__primary button__small strategy-track__management-button"
                          type={ButtonType.button}
                          onClick={openUploadTrackForm}
                        >
                          Залить трек
                        </Button>
                      </div>
                    )}
                  </ProtectedComponent>
                  <ProtectedComponent userAction={UserAction.sandboxTrackRemove}>
                    <div>
                      <Button
                        className="button button__primary button__small strategy-track__management-button"
                        type={ButtonType.button}
                        onClick={deleteTrack}
                      >
                        {loadingTrack ? 'Отменить загрузку' : 'Стереть трек'}
                      </Button>
                    </div>
                  </ProtectedComponent>
                  <ProtectedComponent userAction={UserAction.sandboxModerate}>
                    {!loadingTrack && (
                      <div>
                        <Button
                          className="button button__primary button__small"
                          type={ButtonType.button}
                          onClick={() => requestModerate(RequestModerateAction.Yes)}
                        >
                          На модерацию
                        </Button>
                      </div>
                    )}
                  </ProtectedComponent>
                </>
              )}
              {strategy?.status === StrategyStatus.Moderate && (
                <>
                  <ProtectedComponent userAction={UserAction.sandboxModerateCancel}>
                    <div>
                      <Button
                        className="button button__primary button__small strategy-track__management-button"
                        type={ButtonType.button}
                        onClick={() => moderate(RequestModerateAction.No)}
                      >
                        На доработку
                      </Button>
                    </div>
                  </ProtectedComponent>
                  <ProtectedComponent userAction={UserAction.sandboxModerateAccept}>
                    <div>
                      <Button
                        className="button button__primary button__small strategy-track__management-button"
                        type={ButtonType.button}
                        onClick={() => moderate(RequestModerateAction.Yes)}
                      >
                        Согласовать
                      </Button>
                    </div>
                  </ProtectedComponent>
                  <ProtectedComponent userAction={UserAction.sandboxModerate}>
                    <div>
                      <Button
                        className="button button__primary button__small"
                        type={ButtonType.button}
                        onClick={() => requestModerate(RequestModerateAction.No)}
                      >
                        Отменить модерацию
                      </Button>
                    </div>
                  </ProtectedComponent>
                </>
              )}
              {(strategy?.status === StrategyStatus.Standard || strategy?.status === StrategyStatus.Closed) && (
                <ProtectedComponent userAction={UserAction.sandboxReturn}>
                  <div>
                    <Button
                      className="button button__primary button__small"
                      type={ButtonType.button}
                      onClick={() => toSandbox()}
                    >
                      В песочницу
                    </Button>
                  </div>
                </ProtectedComponent>
              )}
              {actionError && <p className="error-message">{actionError}</p>}
            </div>
          );
        },
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
    ],
    [
      actionError,
      deleteTrack,
      loadingTrack,
      moderate,
      openUploadTrackForm,
      requestModerate,
      strategy?.status,
      toSandbox,
    ],
  );

  const checkStatus = useCallback(() => {
    sandboxTrackStatus(strategyId as string)
      .then((response) => {
        const { success, errorMessage, result } = response;

        if (success) {
          const { error, loading, percentsDone } = result;

          setError(error);
          setLoadingTrack(loading);
          setUploading(percentsDone);
        } else {
          setError(errorMessage);
        }
      })
      .catch(() => {
        setError('Произошла непредвиденная ошибка');
      });
  }, [strategyId]);

  useEffect(() => {
    const interval = 5000;
    const intervalId = setInterval(() => {
      checkStatus();
    }, interval);
    return () => clearInterval(intervalId);
  }, [checkStatus, strategy?.status]);

  useEffect(() => {
    const interval = 5000;
    const intervalId = setInterval(() => {
      strategiesApi.get('');
    }, interval);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div style={{ marginTop: '30px' }}>
      <TableGridLocal columns={columns} data={data} pageSize={25} totalRowCount={1} />
    </div>
  );
};
