import { useCallback, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useStore } from 'effector-react';
import { useForm } from 'react-hook-form';

import { useIIrAudit } from './queries';
import { schema } from './schema';
import { FilterSection } from '../../../components/FilterSection';
import { ControlledDateTimeField } from '../../../components/FormControlsV2/ControlledDateTimeField';
import { ControlledSelectField } from '../../../components/FormControlsV2/ControlledSelectField';
import { ControlledTextField } from '../../../components/FormControlsV2/ControlledTextField';
import { FormProvider } from '../../../components/FormControlsV2/FormProvider';
import { SearchInstrumentField } from '../../../components/SearchInstrumentField';
import { searchApi } from '../../../effector/search';
import { StrategyDictionary$ } from '../../../effector/strategyDictionary';

type FormFields = {
  name: string;
  agreement: string;
  strategy: string;
  iir: string;
  startDate: string;
  endDate: string;
};

export const Iir = () => {
  const [tool, setTool] = useState<string | null>(null);
  const [clearSearch, setClearSearch] = useState<boolean>(false);

  const strategies = useStore(StrategyDictionary$);

  const { mutateAsync: getAudit, isLoading } = useIIrAudit();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormFields>({
    defaultValues: {
      name: '',
      agreement: '',
      strategy: '',
      startDate: '',
      endDate: '',
      iir: '',
    },
    resolver: yupResolver(schema),
  });

  const handleSearchClick = useCallback((securityKey: string) => {
    setTool(securityKey);
    searchApi.clearSuggestion('');
  }, []);

  const handleClearSearchClick = useCallback(() => {
    setTool(null);
  }, []);

  const forceClearSearch = useCallback(() => {
    setClearSearch(true);
    setTimeout(() => setClearSearch(false), 300);
  }, [setClearSearch]);

  const handleResetForm = useCallback(() => {
    reset();
    handleSearchClick('');
    forceClearSearch();
  }, [forceClearSearch, handleSearchClick, reset]);

  const onSubmit = handleSubmit(async (values: FormFields) => {
    const [clientFirstName, clientLastName, clientMiddleName] = values?.name?.split(' ') || [null, null, null];
    const params = {
      clientFirstName: clientFirstName || null,
      clientLastName: clientLastName || null,
      clientMiddleName: clientMiddleName || null,
      agreementNumber: values.agreement || null,
      strategyName: values.strategy || null,
      iirNumber: values.iir || null,
      iirStartDate: values.startDate ? dayjs(values.startDate).format('YYYY-MM-DD HH:mm:ss') : null,
      iirEndDate: values.endDate ? dayjs(values.endDate).format('YYYY-MM-DD HH:mm:ss') : null,
      securityKey: tool || null,
    };

    try {
      await getAudit(params);
    } catch {}
  });

  const strategiesOptions = useMemo(
    () => strategies?.map((strategy) => ({ value: strategy.id, label: strategy.name })) || [],
    [strategies],
  );

  const startDate = watch('startDate');
  const endDate = watch('endDate');

  return (
    <FilterSection>
      <form onSubmit={onSubmit}>
        <FormProvider control={control} errors={errors}>
          <Stack direction="row" gap={2}>
            <ControlledTextField name="name" label="ФИО" />
            <ControlledTextField name="agreement" label="Г/С" />
          </Stack>

          <Stack direction="row" gap={2}>
            <ControlledSelectField name="strategy" label="Стратегия" options={strategiesOptions} />
            <SearchInstrumentField
              forceClear={clearSearch}
              onSearch={handleSearchClick}
              onClear={handleClearSearchClick}
            />
          </Stack>

          <Stack direction="row" gap={2}>
            <ControlledDateTimeField
              name="startDate"
              label="Дата начала"
              format="DD.MM.YYYY"
              maxDate={endDate || Date()}
            />
            <ControlledDateTimeField
              name="endDate"
              label="Дата окончания"
              format="DD.MM.YYYY"
              minDate={startDate}
              maxDate={Date()}
            />
          </Stack>

          <Stack direction="row" gap={2}>
            <ControlledTextField name="iir" label="Номер ИИР" />
          </Stack>
        </FormProvider>

        <Stack direction="row" gap={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            sx={{ mt: 2 }}
            startIcon={isLoading && <CircularProgress size={20} />}
          >
            Сформировать
          </Button>

          <Button
            variant="outlined"
            color="primary"
            size="large"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleResetForm}
            startIcon={isLoading && <CircularProgress size={20} />}
          >
            Сбросить
          </Button>
        </Stack>
      </form>
    </FilterSection>
  );
};
