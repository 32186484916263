/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { Component } from 'react';

import { Uppy } from '@uppy/core';
import { DragDrop } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import cn from 'classnames';

import { ReactComponent as DeleteIcon } from './assets/delete.svg';
import { ReactComponent as FileIcon } from './assets/file.svg';
import { roundNumber } from '../../../../utils';
import { Nullable } from '../../../../utils/types';

import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';

type Props = {
  url: string;
  fieldName: string;
  multiple: boolean;
  fileTypes: string[];
  sizeView: 'kb' | 'mb';
  uploadedFileName?: string;
  disabled?: boolean;
  onUploaded: (fieldName: string, fileName: string) => void;
  onRemove: (fileName: string) => void;
};

type FileInfo = {
  id?: string;
  fileId: Nullable<number>;
  name: string;
  displayName: string;
  size: number;
  progress: number;
  uploadedSize: number;
  deleting: boolean;
};

type State = {
  uploadingFiles: FileInfo[];
  files: FileInfo[];
  error: string;
};

const toKB = (bytes: number) => roundNumber(bytes / 1024, 1);
const toMB = (bytes: number) => roundNumber(toKB(bytes) / 1024, 1);

export class Upload extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      uploadingFiles: [],
      files: [],
      error: '',
    };

    const size5mb = 1024 * 1024 * 5;

    // @ts-ignore
    this.uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        maxFileSize: size5mb,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: this.props.fileTypes,
      },
    })
      .use(XHRUpload, {
        endpoint: this.props.url,
        limit: 3,
        method: 'POST',
        formData: true,
        fieldName: 'file',
      })
      .on('file-added', (file) => {
        const { uploadingFiles } = this.state;
        const fileSizeConverter = this.props.sizeView === 'kb' ? toKB : toMB;

        const fileInfo: FileInfo = {
          id: file.id,
          fileId: null,
          name: file.name,
          displayName: file.name,
          size: fileSizeConverter(file.size),
          progress: 0,
          uploadedSize: 0,
          deleting: false,
        };

        this.setState({ uploadingFiles: [...uploadingFiles, fileInfo] });
      })
      .on('upload-progress', (file, progress) => {
        const { uploadingFiles } = this.state;
        const fileSizeConverter = this.props.sizeView === 'kb' ? toKB : toMB;

        uploadingFiles.forEach((item) => {
          if (item.id === file?.id) {
            item.progress = roundNumber((progress.bytesUploaded / progress.bytesTotal) * 100, 0);
            item.uploadedSize = fileSizeConverter(progress.bytesUploaded);
          }
        });

        this.setState({ uploadingFiles });
      })
      .on('upload-success', (file, response) => {
        const { uploadingFiles, files } = this.state;

        if (response.body && response.body.success) {
          const { result } = response.body;

          const uploadedFile = uploadingFiles.find((item) => item.id === file?.id);
          const newUploadingFiles = uploadingFiles.filter((item) => item.id !== file?.id);

          if (uploadedFile) {
            uploadedFile.fileId = result.id;
            uploadedFile.name = result.name;
            uploadedFile.displayName = this.props.uploadedFileName || result.name;

            const uploadedFiles = [...files, uploadedFile];
            const uploadedFileNames = uploadedFiles.map((item) => item.name).join('');

            this.setState({
              files: uploadedFiles,
            });

            this.props.onUploaded(this.props.fieldName, uploadedFileNames);

            setTimeout(
              () =>
                this.setState({
                  uploadingFiles: newUploadingFiles,
                }),
              500,
            );
          }
        } else if (response.body && !response.body.success) {
          const newUploadingFiles = uploadingFiles.filter((item) => item.id !== file?.id);

          this.setState({
            error: response.body.errorMessage,
            uploadingFiles: newUploadingFiles,
          });
          setTimeout(() => this.setState({ error: '' }), 5000);
        }
      })
      .on('upload-error', (file) => {
        const { uploadingFiles } = this.state;
        const newUploadingFiles = uploadingFiles.filter((item) => item.id !== file?.id);

        this.setState({ error: 'Произошла непредвиденная ошибка' });

        setTimeout(() => this.setState({ error: '', uploadingFiles: newUploadingFiles }), 5000);
      });
  }

  get fileSizeMeasure() {
    return this.props.sizeView === 'kb' ? 'Кб' : 'Мб';
  }

  componentWillUnmount() {
    // @ts-ignore
    this.uppy.close();
  }

  deleteFile = (file: FileInfo) => {
    const { files } = this.state;

    files.forEach((item) => {
      if (item.id === file.id) {
        item.deleting = true;
      }
    });

    this.setState({ files });
    this.removeFile(file);
    //
    // this.props.onRemove({ id: file.fileId }).then(result => {
    //   const { success } = result;
    //
    //   if (success) {
    //     this.removeFile(file);
    //   }
    // });
  };

  cancelUpload = (file: FileInfo) => {
    this.removeFile(file);
  };

  removeFile = (file: FileInfo) => {
    const { files } = this.state;

    const updatedFiles = files.filter((item) => item.id !== file.id);
    const uploadedFileNames = updatedFiles.map((item) => item.name).join('');

    this.props.onUploaded(this.props.fieldName, uploadedFileNames);

    this.setState({ files: updatedFiles });
    // @ts-ignore
    this.uppy.removeFile(file.id);
  };

  render() {
    const { files, uploadingFiles, error } = this.state;

    return (
      <div className="upload-form">
        <div
          className={cn('form-upload', {
            'form-upload-disabled': this.props.disabled,
          })}
        >
          <DragDrop
            // @ts-ignore
            uppy={this.uppy}
            locale={{
              strings: {
                dropHereOr: 'Прикрепить файл',
              },
            }}
          />
        </div>
        {uploadingFiles &&
          uploadingFiles.map((item, index) => (
            <div key={index} className="upload-row">
              <div className="upload-row__header">
                <span className="upload-row__header-name">{item.name}</span>
                <span className="upload-row__header-cancel" onClick={() => this.cancelUpload(item)}>
                  Отменить загрузку
                </span>
              </div>
              <div className="upload-row__progress-bar-wrapper">
                <div className="upload-row__progress-bar" style={{ width: `${item.progress}%` }} />
              </div>
              <div className="upload-row__footer">
                <span className="upload-row__footer-progress">{item.progress}%</span>
                <span className="upload-row__footer-size">
                  {item.uploadedSize}
                  {this.fileSizeMeasure} из {item.size}
                  {this.fileSizeMeasure}
                </span>
              </div>
            </div>
          ))}

        {error && <p className="error-message">{error}</p>}

        <div className="form-control-row__separator" />

        {files &&
          files.map((item, index) => (
            <div
              key={index}
              className={cn('uploaded-row', {
                'uploaded-row-deleting': item.deleting,
              })}
            >
              <FileIcon className="uploaded-row__file" />
              <span className="uploaded-row__name">{item.displayName}</span>
              <span className="uploaded-row__size">
                {item.size}
                {this.fileSizeMeasure}
              </span>
              <DeleteIcon className="uploaded-row__delete" onClick={() => this.deleteFile(item)} />
            </div>
          ))}
      </div>
    );
  }
}
