import { useQuery } from 'react-query';

import { getDelayedSignals, getSandboxDelayedSignals } from '../../../../api';
import { PageParams } from '../../../../components/TableGrid';
import { StrategyStatus } from '../../../../types/strategy';

export const useLoadSignals = (
  id: string | undefined,
  strategyStatus: StrategyStatus | undefined,
  data: PageParams,
) => {
  return useQuery(
    ['loadSignals', id, strategyStatus, data],
    () => {
      const params = {
        strategyId: id,
        data,
      };

      if (strategyStatus === StrategyStatus.Standard || strategyStatus === StrategyStatus.Closed) {
        return getDelayedSignals(params);
      } else {
        return getSandboxDelayedSignals(params);
      }
    },
    {
      enabled: !!id,
    },
  );
};
