export enum DelayedSignalType {
  Standard = 0,
  Activate = 1,
}

export type DelayedSignal = {
  id: number;
  symbol: string;
  name: string;
  filingTime: string;
  weight: number;
  execPrice: number;
  currentPrice?: number;
  stopLoss?: number;
  takeProfit?: number;
  comment: string;
  type: DelayedSignalType;
  fillingTime: string;
};

export type PaginatedDelayedSignal = {
  pageSignals: DelayedSignal[];
  totalSignals: number;
};
