import React from 'react';

import { FormControl } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller } from 'react-hook-form';

import { useFormContext } from '../FormProvider';

interface Props {
  name: string;
  label: string;
  fullWidth?: boolean;
  margin?: 'none' | 'dense' | 'normal';
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const ControlledCheckboxField: React.FC<Props> = ({ name, label, fullWidth, margin, onChange }) => {
  const { control, errors } = useFormContext();

  return (
    <FormControl fullWidth={fullWidth} margin={margin}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                {...field}
                checked={field.value}
                onChange={(event, checked) => {
                  field.onChange(event);
                  onChange?.(event, checked);
                }}
              />
            }
            label={label}
          />
        )}
      />
      {errors?.[name] && <p style={{ color: 'red' }}>{errors[name]?.message}</p>}
    </FormControl>
  );
};
