import React from 'react';

import { FormControl } from '@mui/material';
import { Controller } from 'react-hook-form';

import { useFormContext } from '../FormProvider';
import { Upload } from './Request/upload';

type Props = {
  name: string;
  url: string;
  fileTypes: string[];
  sizeView: 'kb' | 'mb';
  uploadedFileName?: string;
  placeholder?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  multiple?: boolean;
  margin?: 'none' | 'dense' | 'normal';
  onUpload: (fieldName: string, fileName: string) => void;
  onRemove: (fileName: string) => void;
};

export const ControlledFileField: React.FC<Props> = ({
  name,
  url,
  fileTypes,
  sizeView = 'kb',
  uploadedFileName,
  placeholder,
  disabled,
  onRemove,
  onUpload,
  multiple = false,
  fullWidth = true,
  margin = 'normal',
}) => {
  const { control, errors } = useFormContext();

  const styles = {
    fontSize: '14px',
    fontWeight: 600,
    color: '#9598a7',
    marginBottom: '5px',
  };

  return (
    <FormControl fullWidth={fullWidth} margin={margin}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <>
            {placeholder && <div style={styles}>{placeholder}</div>}
            <Upload
              url={url}
              fileTypes={fileTypes}
              sizeView={sizeView}
              uploadedFileName={uploadedFileName}
              multiple={multiple}
              disabled={disabled}
              fieldName={field.name}
              onRemove={onRemove}
              onUploaded={onUpload}
            />
            {!!errors?.[name] && <span style={{ color: 'red', fontSize: '12px' }}>{fieldState.error?.message}</span>}
          </>
        )}
      />
    </FormControl>
  );
};
