import * as React from 'react';

type IHook = {
  (ref: React.RefObject<HTMLElement>, handler: () => void): void;
};

export const useOutSideClick: IHook = (ref, handler) => {
  React.useEffect(() => {
    const onEventFire = (event: Event) => {
      if (ref?.current?.contains(event.target as HTMLDivElement)) {
        return;
      }

      handler();
    };

    document.addEventListener('click', onEventFire, false);
    document.addEventListener('touchstart', onEventFire, false);

    return () => {
      document.removeEventListener('click', onEventFire, false);
      document.removeEventListener('touchstart', onEventFire, false);
    };
  }, [ref, handler]);
};
