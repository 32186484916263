import ClearIcon from '@mui/icons-material/Clear';
import { Box, Typography, IconButton } from '@mui/material';

interface Props<T extends Record<string, any>> {
  label: string;
  name: keyof T;
  file: T[keyof T];
  setValue: (fieldName: keyof T, value: T[keyof T]) => void;
}

export const FilePath = <T extends Record<string, any>>({ name, label, file, setValue }: Props<T>): JSX.Element => (
  <Box sx={{ mb: 2 }}>
    <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 0.5 }}>
      {label}
    </Typography>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="body2" sx={{ mr: 1 }}>
        {file as string}
      </Typography>
      <IconButton size="small" color="error" onClick={() => setValue(name, '' as T[keyof T])}>
        <ClearIcon fontSize="small" />
      </IconButton>
    </Box>
  </Box>
);
