/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useMemo } from 'react';

import { faEdit, faExchangeAlt, faPlus, faRecycle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import { useStore } from 'effector-react';
import { type MRT_ColumnDef } from 'material-react-table';
import moment from 'moment';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { ProtectedComponent } from '../../../components/ProtectedComponent';
import { TableGridLocal } from '../../../components/TableGridLocal';
import { Button, ButtonType } from '../../../components/UIKit/Button';
import { AggregateStrategies$, aggregateStrategiesApi } from '../../../effector/aggregateStrategies';
import { modalApi } from '../../../effector/modals';
import { User$ } from '../../../effector/user';
import { AggregateStrategy } from '../../../types/aggregateStrategy';
import { UserRoles } from '../../../types/userRoles';
import { boolToString } from '../../../utils';
import { UserAction, checkPermissions } from '../../../utils/permissions';
import { MODALS } from '../../../utils/types';
import { PAGE_ADMINISTRATION, PAGE_ADMINISTRATION_STRATEGY_DETAILS } from '../../Routes';

function onlyUnique(value: any, index: number, array: any) {
  return array.indexOf(value) === index;
}

export const Strategies: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useStore(User$);

  const onEdit = useCallback(
    (val: string) => {
      navigate(val);
    },
    [navigate],
  );

  const aggregateStrategiesStore = useStore(AggregateStrategies$);

  useEffect(() => {
    aggregateStrategiesApi.get('');
  }, []);

  const onAddStrategy = () => {
    modalApi.show({
      modalId: MODALS.ADD_STRATEGY,
    });
  };

  const onSplit = () => {
    modalApi.show({
      modalId: MODALS.SPLIT,
    });
  };

  const onUpdateHistory = () => {
    modalApi.show({ modalId: MODALS.UPDATE_HISTORY });
  };

  const isDeveloper = user?.role && user.role === UserRoles.Developer;

  const currencies = aggregateStrategiesStore.strategies.map(({ currency }) => currency).filter(onlyUnique);

  const columns = useMemo<MRT_ColumnDef<AggregateStrategy>[]>(() => {
    if (user) {
      const items: (MRT_ColumnDef<AggregateStrategy> & { action: UserAction })[] = [
        {
          accessorKey: 'name',
          header: 'Название',
          filterVariant: 'text',
          action: UserAction.administrationStrategiesAction,
          Cell: ({ cell }) => (
            <Link to={`${PAGE_ADMINISTRATION}/${PAGE_ADMINISTRATION_STRATEGY_DETAILS}/${cell.row.original.id}`}>
              {cell.getValue() as string}
            </Link>
          ),
        },
        {
          accessorFn: (originalRow) => new Date(originalRow.creationDate),
          id: 'creationDate',
          header: 'Создана',
          filterVariant: 'date-range',
          action: UserAction.administrationStrategiesAction,
          Cell: ({ cell }) => {
            const timeFormatted = moment(cell.row.original.creationDate).format('DD.MM.YYYY HH:mm');
            return <span>{timeFormatted}</span>;
          },
        },
        {
          accessorKey: 'leverage',
          header: 'Плечо',
          filterVariant: 'range',
          filterFn: 'betweenInclusive',
          action: UserAction.administrationStrategiesAction,
          size: 50,
        },
        {
          accessorKey: 'currency',
          header: 'Валюта',
          filterVariant: 'multi-select',
          filterSelectOptions: currencies,
          action: UserAction.administrationStrategiesAction,
          size: 50,
        },
        {
          accessorFn: (originalRow) => boolToString(originalRow.open),
          header: 'Запущена',
          filterVariant: 'select',
          filterSelectOptions: ['Да', 'Нет'],
          action: UserAction.administrationStrategiesAction,
          size: 50,
          Cell: ({ cell }) => boolToString(cell.row.original.open),
        },
        {
          accessorFn: (originalRow) => boolToString(originalRow.isRestricted, { yes: 'Закрытая', no: 'Открытая' }),
          header: 'Видимость',
          filterVariant: 'select',
          filterSelectOptions: ['Закрытая', 'Открытая'],
          action: UserAction.administrationStrategiesAction,
          Cell: ({ cell }) => boolToString(cell.row.original.isRestricted, { yes: 'Закрытая', no: 'Открытая' }),
        },
        {
          accessorFn: (originalRow) => boolToString(originalRow.isBlogger),
          header: 'Стратегия блогера',
          filterVariant: 'select',
          filterSelectOptions: ['Да', 'Нет'],
          action: UserAction.administrationStrategiesAction,
          size: 50,
          Cell: ({ cell }) => boolToString(cell.row.original.isBlogger),
        },
        {
          accessorKey: 'edit',
          header: '',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          enableColumnFilterModes: false,
          enableColumnActions: false,
          enableSorting: false,
          size: 20,
          action: UserAction.administrationStrategiesAction,
          Cell: ({ cell, table }) => {
            const tableState = table.getState();
            const link = `${cell.row.original.id}?${JSON.stringify(tableState.columnFilters)}`;
            return (
              <span className="pointer">
                <FontAwesomeIcon
                  icon={faEdit}
                  title="Редактировать"
                  className={cs({ 'fa-disabled': isDeveloper && !cell.row.original.testMode })}
                  onClick={() => {
                    onEdit(link);
                  }}
                />
              </span>
            );
          },
        },
        {
          accessorKey: 'close',
          header: '',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          enableColumnFilterModes: false,
          enableColumnActions: false,
          enableSorting: false,
          size: 40,
          action: UserAction.administrationStrategiesAction,
          Cell: ({ cell }) => {
            return (
              <span className="pointer">
                <FontAwesomeIcon
                  icon={faExchangeAlt}
                  title="Запустить/Убрать"
                  className={cs({
                    fall: cell.row.original.testMode || !isDeveloper,
                    'fa-disabled': isDeveloper && !cell.row.original.testMode,
                  })}
                  onClick={() => {
                    // @ts-ignore
                    modalApi.show({
                      modalId: MODALS.OPEN_STRATEGY,
                      data: {
                        id: cell.row.original.id,
                        open: cell.row.original.open,
                      },
                    });
                  }}
                />
              </span>
            );
          },
        },
        {
          accessorKey: 'remove',
          header: '',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          enableColumnFilterModes: false,
          enableColumnActions: false,
          enableSorting: false,
          size: 40,
          action: UserAction.administrationStrategiesAction,
          Cell: ({ cell }) => {
            return (
              <span className="pointer">
                <FontAwesomeIcon
                  icon={faRecycle}
                  title="Удалить"
                  className={cs({
                    'fa-disabled': (isDeveloper && !cell.row.original.testMode) || cell.row.original.open,
                  })}
                  onClick={() =>
                    modalApi.show({
                      modalId: MODALS.OPEN_STRATEGY,
                      data: {
                        id: cell.row.original.id,
                        active: cell.row.original.active,
                      },
                    })
                  }
                />
              </span>
            );
          },
        },
      ];

      const filteredColumns = items.filter((item) => checkPermissions(user.role, item.action as UserAction));
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return filteredColumns.map(({ action, ...rest }) => rest);
    }
    return [];
  }, [currencies, user, isDeveloper, onEdit]);

  const columnsFilters = useMemo(() => {
    if (!location.search) {
      return [];
    }

    let params = location.search;
    if (params.startsWith('?')) {
      params = params.substring(1);
    }

    return JSON.parse(decodeURI(params));
  }, [location]);

  return (
    <>
      <ProtectedComponent userAction={UserAction.administrationDeveloper}>
        <div className="form__group">
          <br />
          <p>Для данного пользователя доступно редактирование только тестовых стратегий.</p>
          <br />
        </div>
      </ProtectedComponent>

      <div className="form__group">
        <ProtectedComponent userAction={UserAction.administrationStrategiesAction}>
          <Button
            className="button button__primary button__small form__button-inline"
            type={ButtonType.button}
            onClick={onAddStrategy}
          >
            <FontAwesomeIcon icon={faPlus} /> Добавить
          </Button>
        </ProtectedComponent>

        <Button
          className="button button__primary button__small form__button-inline"
          type={ButtonType.button}
          onClick={onUpdateHistory}
        >
          Пересчитать рейтинг
        </Button>

        <Button
          className="button button__primary button__small form__button-inline"
          type={ButtonType.button}
          onClick={onSplit}
        >
          Сплит
        </Button>
      </div>

      <TableGridLocal
        columns={columns}
        columnFilters={columnsFilters}
        data={aggregateStrategiesStore.strategies}
        totalRowCount={aggregateStrategiesStore.strategies.length}
      />
    </>
  );
};
