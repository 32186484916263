import { useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';

import type1 from './assets/type1.png';
import type2 from './assets/type2.png';
import type3 from './assets/type3.png';
import type4 from './assets/type4.png';
import { useUpdateBanner, useUtms } from './queries';
import { schema } from './schema';
import { ControlledRadioGroupField } from '../../../components/FormControlsV2/ControlledRadioGroupField';
import { ControlledSelectField } from '../../../components/FormControlsV2/ControlledSelectField';
import { ControlledTextField } from '../../../components/FormControlsV2/ControlledTextField';
import { FormProvider } from '../../../components/FormControlsV2/FormProvider';
import { BannerType, Banner } from '../../../types/banner';

const banners = [
  {
    id: BannerType.one,
    name: <img className="form-control__img" src={type1} alt="" />,
  },
  {
    id: BannerType.two,
    name: <img className="form-control__img" src={type2} alt="" />,
  },
  {
    id: BannerType.three,
    name: <img className="form-control__img" src={type3} alt="" />,
  },
  {
    id: BannerType.four,
    name: <img className="form-control__img" src={type4} alt="" />,
  },
];

type FormFields = {
  title: string;
  subtitle: string;
  buttonText: string;
  url: string;
  utm: string;
  type: BannerType;
  order: number;
};

interface Props {
  banner?: Banner | null;
  onUpdate: () => void;
}

export function BannerForm({ banner, onUpdate }: Props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { data } = useUtms();
  const { mutateAsync } = useUpdateBanner();

  const isUpdate = !!banner;

  const defaultValues = banner
    ? {
        title: banner.title,
        subtitle: banner.subtitle,
        buttonText: banner.buttonText,
        url: banner.url,
        type: banner.type,
        order: banner.order,
        utm: banner.utm,
      }
    : {
        title: '',
        subtitle: '',
        buttonText: '',
        url: '',
        utm: '',
        type: BannerType.one,
        order: 0,
      };

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FormFields>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const utms = useMemo(() => data?.result || [], [data]);

  const utmOptions = useMemo(() => utms.map((utm) => ({ value: utm.id, label: utm.name })), [utms]);

  const bannerOptions = useMemo(() => banners.map((b) => ({ value: b.id, label: b.name })), []);

  const onSubmit = async (data: FormFields) => {
    setLoading(true);
    const requestData = {
      ...banner,
      ...data,
      utm: data?.utm,
      order: Number(data.order),
    };

    try {
      const result: { errorMessage?: string } = await mutateAsync(requestData);

      if (result.errorMessage) {
        setError(result.errorMessage);
        return;
      }

      onUpdate();
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      setError(err?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (banner && utms.length > 0) {
      setValue('utm', banner.utm || '');
    }
  }, [banner, utms, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormProvider control={control} errors={errors}>
        <ControlledTextField name="title" label="Заголовок" />

        <ControlledTextField name="subtitle" label="Подзаголовок" />

        <ControlledTextField name="order" label="Порядковый номер" type="number" />

        <ControlledSelectField name="utm" label="Utm-метка" options={utmOptions} />

        <ControlledRadioGroupField name="type" options={bannerOptions} row />

        <ControlledTextField name="buttonText" label="Текст кнопки" />

        <ControlledTextField name="url" label="Целевой адрес" />
      </FormProvider>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!isDirty || loading}
        fullWidth
        startIcon={loading && <CircularProgress size={20} />}
      >
        {isUpdate ? 'Обновить' : 'Добавить'}
      </Button>

      {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
    </form>
  );
}
