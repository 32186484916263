import React, { useEffect, useMemo, useState } from 'react';

import { useStore } from 'effector-react';
import { Field, FieldArray, Form, Formik, FormikProps } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { AgreementHelper, ClientInfo } from './agreementHelper';
import { EditListArray } from './EditListControl';
import { MaxWeightsControl } from './MaxWeightsControl';
import { useServicePackages, useTariffs } from './queries';
import { getStrategyInfo, getClientInfo } from '../../../api/index';
import { Checkbox } from '../../../components/FormControls/Checkbox';
import { DatePicker } from '../../../components/FormControls/DatePicker';
import { File } from '../../../components/FormControls/File';
import { Input } from '../../../components/FormControls/Input';
import { Select } from '../../../components/FormControls/Select';
import { TextArea } from '../../../components/FormControls/TextArea';
import { TextEditor } from '../../../components/FormControls/TextEditor';
import { Button, ButtonType } from '../../../components/UIKit/Button';
import { ImagePreview } from '../../../components/UIKit/ImagePreview';
import { categories, recalcModes } from '../../../const/strategyRefs';
import { ERRORS_TEXTS } from '../../../const/validation';
import { aggregateStrategiesApi } from '../../../effector/aggregateStrategies';
import { strategyInfoRef$ } from '../../../effector/aggregateStrategyInfoRefs';
import { Config$ } from '../../../effector/config';
import { currencyInfo$ } from '../../../effector/currencies';
import { Strategies$ } from '../../../effector/strategies';
import { tariffs$ } from '../../../effector/strategyHelpers';
import { userInfo } from '../../../effector/userInfoList';
import { AggregateStrategy, InfoPart, RecalcMode, ServicePackage } from '../../../types/aggregateStrategy';
import { Board, Currency, MarketTool, StrategyDuration, TradeMarket } from '../../../types/aggregateStrategyInfoRef';
import { UserType } from '../../../types/aggregateUser';
import { Option, PackageOption } from '../../../types/option';
import { Profile } from '../../../types/profile';
import { Nullable } from '../../../utils/types';
import { PAGE_ADMINISTRATION, PAGE_ADMINISTRATION_STRATEGIES } from '../../Routes';

const autofollowKeyPrefix: string = 'autofollow_package_';
const autoconsaltKeyPrefix: string = 'autoconsalt_package_';

function areAllPackagesCovered(
  packages: PackageOption[],
  formValues: Record<string, any>,
  prefix: string = 'autofollow_package_',
): boolean {
  return packages.every((pkg) => {
    const prefixKey = `${prefix}${pkg.id}`;

    return !!formValues[prefixKey];
  });
}

function mapFormValuesToServicePackages(packages: PackageOption[], formValues: Record<string, any>): ServicePackage[] {
  const result: ServicePackage[] = [];

  packages.forEach((pkg) => {
    const autofollowKey = `${autofollowKeyPrefix}${pkg.id}`;
    const autoconsaltKey = `${autoconsaltKeyPrefix}${pkg.id}`;

    if (formValues.isAutofollowPS && formValues[autofollowKey]) {
      result.push({
        servicePackageId: Number(pkg.id),
        tariffId: formValues[autofollowKey].id,
        strategyType: 1,
      });
    }

    if (formValues.isAutoconsultPS && formValues[autoconsaltKey]) {
      result.push({
        servicePackageId: Number(pkg.id),
        tariffId: formValues[autoconsaltKey].id,
        strategyType: 2,
      });
    }
  });

  return result;
}

function initializeFormikValues(
  servicePackages: ServicePackage[],
  tariffs: { id: number; name: string }[],
  setFieldValue: (field: string, value: any) => void,
) {
  servicePackages.forEach((item) => {
    const key =
      item.strategyType === 1
        ? `autofollow_package_${item.servicePackageId}`
        : `autoconsalt_package_${item.servicePackageId}`;

    const tariff = tariffs.find((tariff) => tariff.id === item.tariffId);

    if (tariff) {
      setFieldValue(key, tariff);
    } else {
      console.warn(`Tariff with ID ${item.tariffId} not found for service package ${item.servicePackageId}`);
    }
  });
}

type FormFields = {
  //edit: boolean;
  id: string | undefined;
  /**
   * Описание
   */
  title: string;
  author: Option;
  managerIds: Option[];
  isBlogger: boolean;
  infoParts: InfoPart[];
  riskReasons: InfoPart[];
  imageUrl?: string | null;
  description: string;
  disclaimer: string;
  welcomeLetter: string;
  subscription: string;
  /**
   * Характеристики
   */
  profile: Profile | undefined;
  currency: Currency;
  minSumma: number;
  recommendedAmount: number;
  repoWithCcp: boolean;
  capacity?: number;
  estimatedProfit?: number;
  estimatedTradesPerWeek?: number;
  period: StrategyDuration; // Срок
  category: { id: number; name: string }; // Категория {квал|не квал}
  markets: TradeMarket[];
  tools: MarketTool[];
  boards: Board[];
  securities: ClientInfo[];
  maxSignalWeights: { classCode: string; value: number }[];
  index?: { id: number; name: string }; // fintarget.index
  hideIndexOnChart: boolean;
  hideIndexInWL: boolean;

  /**
   * услуги
   */
  isAutofollow: boolean;
  tariffAf?: { id: number; name: string; guid: string; category: string };
  isAutoconsult: boolean;
  tariffAc?: { id: number; name: string; guid: string };
  isAutofollowPS: boolean;
  isAutoconsultPS: boolean;

  /**
   * Для кабинета: ставки по плечу - необязательно
   */
  rateShort: Nullable<number>;
  rateLong: Nullable<number>;

  /**
   * Параметры
   */
  maxPosWeight: number; // % Максимальная доля инструмента
  maxSignalWeight: number;
  drawdown: string; // % Ограничение убытка по инструменту
  leverage: number;
  recalcMode: { name: string; id: RecalcMode };
  tradeType: { name: string; id: number };
  plStartDate?: Date;
  publishingDate?: Date;
  isAlgo: boolean; // Алгоритмическая
  testMode: boolean; // Тестовая
  closed: boolean; // !open
  isRestricted: boolean;
  restrictedClients: ClientInfo[];
  hasParent: boolean;
  parent: { id: string; name: string };
  tests: { id: string; name: string }[];

  /**
   * Отображение
   */
  isIis: boolean;
  isCrisis: boolean;
  isRecommended: boolean;
  isHidden: boolean;
  stockMarketMMVB: boolean;
  fortsRTS: boolean;
  creditGO: boolean;
  currencyMarket: boolean;
  spbRTS: boolean;
  currencyMarketEBS: boolean;
  stockMarketOffexchange: boolean;
  mmvbUSD: boolean;
  fortsRTSEBS: boolean;
  lse: boolean;
  usMarkets: boolean;
};

type FormProps = {
  strategy: Nullable<AggregateStrategy>;
  error?: string;
  send: boolean;
  cancel: () => void;
  isUpdate: boolean;
} & FormikProps<FormFields>;

const validationSchema = Yup.object().shape({
  title: Yup.string().required(ERRORS_TEXTS.required),
  author: Yup.object().required(ERRORS_TEXTS.required),
  managerIds: Yup.array(),
  imageUrl: Yup.string().nullable().default(null),
  description: Yup.string().nullable().default(''),
  welcomeletter: Yup.string().default(''),
  disclaimer: Yup.string().nullable().default(''),
  profile: Yup.object().nullable().default(null),
  currency: Yup.object().required(ERRORS_TEXTS.required),
  minSumma: Yup.number().positive().required(ERRORS_TEXTS.positive),
  recommendedAmount: Yup.number().positive(ERRORS_TEXTS.positive).nullable(),
  capacity: Yup.number().positive(ERRORS_TEXTS.positive).nullable().default(null),
  estimatedProfit: Yup.number().positive(ERRORS_TEXTS.positive).nullable().default(null),
  estimatedTradesPerWeek: Yup.number().positive(ERRORS_TEXTS.positive).nullable().default(null),
  period: Yup.object().required(ERRORS_TEXTS.required),
  category: Yup.object().required(ERRORS_TEXTS.required),

  isAutofollow: Yup.boolean(),
  tariffAf: Yup.object()
    .nullable()
    .when('isAutofollow', {
      is: true,
      then: Yup.object().nullable().required(ERRORS_TEXTS.required),
    }),
  isAutoconsult: Yup.boolean(),
  tariffAc: Yup.object()
    .nullable()
    .when('isAutoconsult', {
      is: true,
      then: Yup.object().nullable().required(ERRORS_TEXTS.required),
    }),

  maxPosWeight: Yup.number().positive(ERRORS_TEXTS.positive),
  maxSignalWeight: Yup.number().positive(ERRORS_TEXTS.positive).nullable().default(null),
  leverage: Yup.number().min(0, ERRORS_TEXTS.positive),
  recalcMode: Yup.object().required(ERRORS_TEXTS.required),
  hasParent: Yup.boolean(),
  parent: Yup.object().when('hasParent', {
    is: true,
    then: Yup.object().required(ERRORS_TEXTS.required),
  }),
});

const getSecurityInfo = async (securities: string[]) => {
  return await {
    success: true,
    result: securities
      ? securities.map((s) => {
          return { id: s, name: s, value: s };
        })
      : [],
  };
};

const FormTemplate: React.FC<FormProps> = (props) => {
  const configStore = useStore(Config$);
  const disabled = /*Object.keys(props.errors).length > 0 ||*/ !props.dirty || props.send;

  const [hasParent, setParent] = useState(props.values.hasParent);
  const [closed, setClosed] = useState(props.values.isRestricted);
  const userInfoStore = useStore(userInfo);
  const strategiesStore = useStore(Strategies$);
  const infoStore = useStore(strategyInfoRef$);
  const tariffStore = useStore(tariffs$);
  const currencyStore = useStore(currencyInfo$);

  const { data: servicePackagesResult } = useServicePackages();

  const { data: tariffsResult } = useTariffs();

  const users =
    userInfoStore.total > 0
      ? userInfoStore.users
          .filter((u) => u.userType !== UserType.Representative)
          .map((u) => {
            return { id: u.id, name: u.displayName };
          })
      : [];

  const durations = infoStore.data.durations;
  const tests = infoStore.data.tests;

  const tradeTypes = infoStore.data.tradeTypes
    .filter((t) => t.enabled)
    .map((t) => {
      return { id: t.id, name: t.description };
    });

  const parentStrategies = strategiesStore
    .filter((s) => !s.isInvestbox && s.parentStrategy === null)
    .map((s) => {
      return { id: s.id, name: s.name };
    });

  const afTariffs = tariffStore.data
    .filter((q) => q.kind == 1)
    .map((q) => {
      return { id: q.id, guid: q.guid, name: q.description, category: q.category };
    });

  const acTariffs = tariffStore.data
    .filter((q) => q.kind == 2)
    .map((q) => {
      return { id: q.id, name: q.description, guid: q.guid };
    });

  const tariffs = useMemo(
    () => tariffsResult?.map(({ id, description }) => ({ id, name: description })) || [],
    [tariffsResult],
  );

  const afTariffsPS = useMemo(
    () =>
      tariffsResult?.filter(({ kind }) => kind === 1)?.map(({ id, description }) => ({ id, name: description })) || [],
    [tariffsResult],
  );

  const acTariffsPS = useMemo(
    () =>
      tariffsResult?.filter(({ kind }) => kind === 2)?.map(({ id, description }) => ({ id, name: description })) || [],
    [tariffsResult],
  );

  useEffect(() => {
    if (tariffs.length) {
      initializeFormikValues(props.strategy?.servicePackageTariffs || [], tariffs, props.setFieldValue);
      props.setFieldValue;
    }
  }, [props.strategy, props.setFieldValue, tariffs]);

  const indexes = infoStore.data.indexes.map((q) => {
    return { id: q.id, name: q.name };
  });

  const friendlyUrl = configStore?.friendlyUrl;

  const packages = servicePackagesResult || [];

  return (
    <Form className="form" noValidate>
      <div>
        <p className="strategies-params__item-name">Описание</p>
        <div className="form__group">
          <p>Идентификатор стратегии: {props.values.id}</p>
        </div>

        <div className="strategies__selection">
          <div className="form__group">
            <Field name="title" type="text" placeholder="Название" component={Input} />
          </div>
          <div className="form__group">
            <Field name="author" placeholder="Автор" options={users} component={Select} />
          </div>
          <div className="form__group">
            <Field name="managerIds" placeholder="Управляющие" options={users} multiple={true} component={Select} />
          </div>
          <div className="form__group">
            <Field name="isBlogger" text="Стратегия блогера" component={Checkbox} />
          </div>
          {friendlyUrl && props.values.imageUrl && (
            <div className="form__group">
              <ImagePreview
                image={`${friendlyUrl}${props.values.imageUrl}`}
                onDelete={() => props.setFieldValue('imageUrl', null)}
              />
            </div>
          )}
          <div className="form__group">
            <Field
              name="imageUrl"
              placeholder="Большое изображение"
              url="api/admin/files/upload_strategy_picture"
              fileTypes={['.png', '.jpg', '.jpeg', '.gif']}
              onRemove={() => null}
              onUpload={props.setFieldValue}
              component={File}
            />
          </div>
          <div className="form__group">
            <Field name="description" placeholder="Описание" component={TextEditor} />
          </div>
          <div className="form__group">
            <div className="form-control__placeholder-custom">Преимущества</div>
            <FieldArray name="infoParts" component={EditListArray} />
          </div>
          <div className="form__group">
            <div className="form-control__placeholder-custom">Риски</div>
            <FieldArray name="riskReasons" component={EditListArray} />
          </div>
          <div className="form__group">
            <Field name="disclaimer" placeholder="Дисклеймер" component={TextEditor} />
          </div>
          <div className="form__group">
            <Field name="welcomeLetter" type="text" placeholder="Текст для Welcome letter" component={TextArea} />
          </div>
          <div className="form__group">
            <Field name="subscription" type="text" placeholder="Подпись под графиком" component={Input} />
          </div>
          <div className="form__group">
            <Field name="colour" placeholder="Цвет фона для МБ" component={Select} options={['#1', '#2']} />
          </div>
        </div>

        <div className="form__group">
          <p className="strategies-params__item-name">Характеристики</p>
        </div>
        <div className="strategies__selection">
          <div className="form__group">
            <Field
              name="profile"
              placeholder="Инвестпрофиль"
              options={infoStore.data.profiles}
              isClearable={true}
              component={Select}
            />
          </div>
          <div className="form__group">
            <Field name="currency" placeholder="Валюта" options={currencyStore} component={Select} />
          </div>
          <div className="form__group">
            <Field type="number" name="minSumma" placeholder="Минимальная сумма" component={Input} />
          </div>
          <div className="form__group">
            <Field type="number" name="recommendedAmount" placeholder="Рекомендуемая сумма" component={Input} />
          </div>
          <div className="form__group">
            <Field type="number" name="capacity" placeholder="Ёмкость" component={Input} />
          </div>
          <div className="form__group">
            <Field type="number" name="estimatedProfit" placeholder="Прогнозная доходность" component={Input} />
          </div>
          <div className="form__group">
            <Field
              type="number"
              name="estimatedTradesPerWeek"
              placeholder="Прогнозная частота сделок"
              component={Input}
            />
          </div>
          <div className="form__group">
            <Field name="period" placeholder="Срок" options={durations} component={Select} />
          </div>
          <div className="form__group">
            <Field name="category" placeholder="Категория" options={categories} component={Select} />
          </div>
          <div className="table-filter">
            <div className="table-filter__title">Бенчмарк</div>
            <div className="form__group">
              <Field name="index" placeholder="Индекс" options={indexes} component={Select} />
            </div>
            <div className="form__group">
              <Field name="hideIndexOnChart" text="Скрывать на графике" component={Checkbox} />
            </div>
            <div className="form__group">
              <Field name="hideIndexInWL" text="Скрывать в Welcome letter" component={Checkbox} />
            </div>
          </div>

          <div className="table-filter">
            <div className="table-filter__title">Площадки</div>
            <div className="form__group">
              <Field
                name="markets"
                placeholder="Рынки"
                options={infoStore.data.markets}
                multiple={true}
                component={Select}
              />
            </div>
            <div className="form__group">
              <Field
                name="tools"
                placeholder="Площадки"
                options={infoStore.data.marketTools}
                multiple={true}
                component={Select}
              />
            </div>
            <div className="form__group">
              <Field
                name="boards"
                placeholder="Торговые системы"
                options={infoStore.data.boards}
                multiple={true}
                component={Select}
              />
            </div>
          </div>

          <div className="table-filter">
            <div className="table-filter__title">Тесты</div>
            <div className="form__group">
              <Field
                name="tests"
                options={tests}
                placeholder="Тесты стратегии"
                isClearable={true}
                multiple={true}
                component={Select}
              />
            </div>
          </div>

          <div className="table-filter">
            <div className="table-filter__title">Инструменты</div>
            <div className="form__group">
              <Field
                name="securities"
                disabled={false}
                placeholder="Маска инструмента"
                verify={getSecurityInfo}
                verifyError="Не удается определить код инструмента"
                useKeyValue={false}
                component={AgreementHelper}
              />
            </div>
          </div>

          <div className="table-filter">
            <div className="table-filter__title">Услуги</div>
            <div className="form__group">
              <Field name="isAutofollow" text="Автоследование" component={Checkbox} />
            </div>
            <div className="form__group">
              <Field name="tariffAf" options={afTariffs} placeholder="Тариф" isClearable={true} component={Select} />
            </div>
            <div className="form__group">
              <Field name="isAutoconsult" text="Автоконсультирование" component={Checkbox} />
            </div>
            <div className="form__group">
              <Field name="tariffAc" placeholder="Тариф" options={acTariffs} isClearable={true} component={Select} />
            </div>

            <div className="form__group">
              <Field name="isAutofollowPS" text="Автоследование по пакету услуг" component={Checkbox} />

              {props.values.isAutofollowPS && (
                <table className="table">
                  <thead>
                    <tr className="table__tr">
                      <th className="table__th">Пакет услуг</th>
                      <th className="table__th">Тариф</th>
                    </tr>
                  </thead>
                  {packages.map(({ name, id }) => (
                    <tr key={id} className="table__tr">
                      <td>{name}</td>
                      <td width="50%">
                        <Field name={`${autofollowKeyPrefix}${id}`} options={afTariffsPS} component={Select} />
                      </td>
                    </tr>
                  ))}
                </table>
              )}
            </div>

            <div className="form__group">
              <Field name="isAutoconsultPS" text="Автоконсультирование по пакету услуг" component={Checkbox} />

              {props.values.isAutoconsultPS && (
                <table className="table">
                  <thead>
                    <tr className="table__tr">
                      <th className="table__th">Пакет услуг</th>
                      <th className="table__th">Тариф</th>
                    </tr>
                  </thead>
                  {packages.map(({ name, id }) => (
                    <tr key={id} className="table__tr">
                      <td>{name}</td>
                      <td width="50%">
                        <Field name={`${autoconsaltKeyPrefix}${id}`} options={acTariffsPS} component={Select} />
                      </td>
                    </tr>
                  ))}
                </table>
              )}
            </div>
          </div>

          <div className="table-filter">
            <div className="table-filter__title">Комиссия за плечо</div>
            <div className="form__group">
              <Field name="rateShort" type="number" placeholder="Ставка для короткой позиции" component={Input} />
            </div>
            <div className="form__group">
              <Field name="rateLong" type="number" placeholder="Ставка для длинной позиции" component={Input} />
            </div>
          </div>
          <div className="table-filter">
            <div className="table-filter__title">Площадки ЕВА</div>
            <div className="form-control-group">
              <Field
                name="stockMarketMMVB"
                className="form-control-group__item3"
                text="StockMarketMMVB"
                component={Checkbox}
              />
              <Field name="fortsRTS" className="form-control-group__item3" text="FortsRTS" component={Checkbox} />
              <Field name="creditGO" className="form-control-group__item3" text="CreditGO" component={Checkbox} />
            </div>
            <div className="form-control-group">
              <Field
                name="currencyMarket"
                className="form-control-group__item3"
                text="CurrencyMarket"
                component={Checkbox}
              />
              <Field name="spbRTS" className="form-control-group__item3" text="SpbRTS" component={Checkbox} />
              <Field
                name="currencyMarketEBS"
                className="form-control-group__item3"
                text="CurrencyMarketEBS"
                component={Checkbox}
              />
            </div>
            <div className="form-control-group">
              <Field
                name="stockMarketOffexchange"
                className="form-control-group__item3"
                text="StockMarketOffexchange"
                component={Checkbox}
              />
              <Field name="mmvbUSD" className="form-control-group__item3" text="MmvbUSD" component={Checkbox} />
              <Field name="fortsRTSEBS" className="form-control-group__item3" text="FortsRTSEBS" component={Checkbox} />
            </div>
            <div className="form-control-group">
              <Field name="lse" className="form-control-group__item" text="Lse" component={Checkbox} />
              <Field name="usMarkets" className="form-control-group__item" text="UsMarkets" component={Checkbox} />
            </div>
          </div>
        </div>

        <div className="form__group">
          <p className="strategies-params__item-name">Параметры</p>
        </div>
        <div className="strategies__selection">
          <div className="form__group">
            <Field name="maxPosWeight" type="number" placeholder="Максимальная доля инструмента, %" component={Input} />
          </div>
          <div className="form__group">
            <Field
              name="maxSignalWeight"
              type="number"
              placeholder="Максимальная доля инструмента в сигнале, %"
              component={Input}
            />
          </div>
          <div className="table-filter">
            <div className="table-filter__title">Максимальные доли типов в сигнале, %</div>
            <Field name="maxSignalWeights" disabled={false} component={MaxWeightsControl} />
          </div>
          <div className="form__group">
            <Field
              name="drawdown"
              type="text"
              placeholder="Ограничение убытка по инструменту - не используется"
              disabled={true}
              component={Input}
            />
          </div>
          <div className="form__group">
            <Field name="leverage" type="number" placeholder="Используемое плечо" component={Input} />
          </div>
          <div className="form__group">
            <Field name="isAlgo" text="Без пересчета весов" component={Checkbox} />
          </div>
          <div className="form__group">
            <Field name="testMode" text="Тестовая" component={Checkbox} />
          </div>
          <div className="form__group">
            <Field
              name="plStartDate"
              placeholder="Начальная дата рассчета доходности"
              dropdownMode="select"
              dateFormat="dd.MM.yyyy"
              showMonthDropdown
              showYearDropdown
              isClearable={true}
              component={DatePicker}
            />
          </div>
          <div className="form__group">
            <Field
              name="publishingDate"
              placeholder="Дата начала действия продукта"
              dropdownMode="select"
              dateFormat="dd.MM.yyyy"
              showMonthDropdown
              showYearDropdown
              isClearable={true}
              component={DatePicker}
            />
          </div>
          <div className="form__group">
            <Field name="recalcMode" placeholder="Пересчет весов" options={recalcModes} component={Select} />
          </div>
          <div className="form__group">
            <Field
              name="tradeType"
              placeholder="Тип проторговки"
              options={tradeTypes}
              isClearable={true}
              component={Select}
            />
          </div>

          <div className="table-filter">
            <div className="form__group">
              <Field name="hasParent" text="Дочерняя" onChange={(v: boolean) => setParent(v)} component={Checkbox} />
            </div>
            <div className="form__group">
              <Field
                name="parent"
                placeholder="Родительская стратегия"
                disabled={!hasParent}
                options={parentStrategies}
                component={Select}
              />
            </div>
          </div>
          <div className="table-filter">
            <div className="form__group">
              <Field
                name="isRestricted"
                text="Закрытая"
                onChange={(v: boolean) => {
                  setClosed(v);
                }}
                component={Checkbox}
              />
            </div>
            <div className="form__group">
              <Field
                name="restrictedClients"
                disabled={!closed}
                placeholder="Номер соглашения"
                verify={getClientInfo}
                verifyError="Не удается определить клиента по номеру соглашения"
                component={AgreementHelper}
              />
            </div>
          </div>
        </div>

        <div className="form__group">
          <p className="strategies-params__item-name">Отображение</p>
          <div className="strategies__selection">
            <div className="form__group">
              <Field name="isIis" text="Доступно для ИИС" component={Checkbox} />
            </div>
            <div className="form__group">
              <Field name="isRecommended" text="Рекомендуем" component={Checkbox} />
            </div>
            <div className="form__group">
              <Field name="isHidden" text="Скрывать состав" component={Checkbox} />
            </div>
            <div className="form__group">
              <Field name="repoWithCcp" text="РЕПО с ЦК" component={Checkbox} />
            </div>
          </div>
        </div>

        <div className="form__group">
          <div className="form-signal__signal">
            <div className="form-signal__signal-wrapper form-signal__signal-types">
              <Button className="button__primary button__large" type={ButtonType.submit} disabled={disabled}>
                Изменить
              </Button>
              <Button className="button__large" type={ButtonType.button} onClick={props.cancel} disabled={false}>
                Отмена
              </Button>
            </div>
          </div>
        </div>

        {props.error && <p className="error-message">{props.error}</p>}
      </div>
    </Form>
  );
};

export const EditStrategy: React.FC<{ notify: (msg: string) => void }> = ({ notify }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const initError: string | undefined = undefined; // #fixme переделать на partial

  const [error, setError] = useState(initError);
  const [, setUpload] = useState(false);

  const { data: packages } = useServicePackages();

  const strategiesStore = useStore(Strategies$);
  const infoStore = useStore(strategyInfoRef$);
  const userInfoStore = useStore(userInfo);
  const tariffStore = useStore(tariffs$);
  const currencyStore = useStore(currencyInfo$);

  const [strategy, setStrategy] = useState<Nullable<AggregateStrategy>>(null);
  const [loadError, setLoadError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (id) {
      getStrategyInfo(id)
        .then((s) => setStrategy(s.result))
        .catch((e) => setLoadError(e.toString()));
    }
  }, []);

  const complete = () => {
    navigate(`${PAGE_ADMINISTRATION}/${PAGE_ADMINISTRATION_STRATEGIES}${location.search}`);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorHandler = (result) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (result.errorMessage) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setError(result.errorMessage);
    } else {
      complete();
      notify('Изменения сохранены');
    }
  };

  const cancel = () => {
    complete();
  };

  const submit = (values: FormFields) => {
    const { isAutofollowPS, isAutoconsultPS } = values;

    if (isAutofollowPS) {
      const covered = areAllPackagesCovered(packages || [], values, autofollowKeyPrefix);
      if (!covered) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setError('Для всех пакетов автоследования должны быть выбраны тарифы');
        return;
      }
    }

    if (isAutoconsultPS) {
      const covered = areAllPackagesCovered(packages || [], values, autoconsaltKeyPrefix);
      if (!covered) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setError('Для всех пакетов автоконсультирования должны быть выбраны тарифы');
        return;
      }
    }

    setUpload(true);

    const data: Partial<AggregateStrategy> = {
      id: values.id ? values.id : '',
      name: values.title,
      parentId: values.hasParent ? values.parent.id : undefined,
      managerId: Number(values.author.id),
      managerName: values.author.name,
      managerIds: values.managerIds?.map(({ id: managerId }) => Number(managerId)) || [],
      creationDate: new Date().toDateString(),
      currency: values.currency.code,
      leverage: values.leverage,
      recalcMode: values.recalcMode.id,
      tradeType: values.tradeType?.id ?? undefined,
      noWeightCorrection: 0, // unknown
      securities: JSON.stringify(values.securities ? values.securities.map((s) => s.value) : []),
      maxSignalWeights: values.maxSignalWeights,
      maxInstrumentWeight: values.maxPosWeight,
      maxSignalWeight: values.maxSignalWeight,
      subscriptionThreshold: values.minSumma,
      recommendedAmount: values.recommendedAmount,
      repoWithCcp: values.repoWithCcp,
      capacity: values.capacity,
      estimatedProfit: values.estimatedProfit,
      estimatedTradesPerWeek: values.estimatedTradesPerWeek,
      active: true,
      rating: 0,
      autofollow: values.isAutofollow,
      autoconsult: values.isAutoconsult,
      afTariffId: values.tariffAf === undefined ? undefined : values.tariffAf.id,
      acTariffId: values.tariffAc === undefined ? undefined : values.tariffAc.id,
      category: values.tariffAf === undefined ? undefined : values.tariffAf.category,
      price: values.tariffAc === undefined ? null : values.tariffAc.name,
      chartComment: values.subscription,
      //capacity: 0, // не используется
      imageUrl: values.imageUrl ? values.imageUrl : null,
      descriptionHtml: values.description,
      disclaimer: values.disclaimer,
      durationId: values.period.id,
      estimatedDrawdown: 0, // obsolete
      actualProfit: 0, // calculated
      forQualifiedInvestorsOnly: Number(values.category.id) === 0,
      marketIds: values.markets.map((q) => q.id),
      marketToolIds: values.tools.map((q) => q.id),
      boardIds: values.boards.map((q) => q.id),
      plStartDate: values.plStartDate?.toDateString(),
      publishingDate: values.publishingDate?.toDateString(),
      hidePortfolio: values.isHidden,
      iis: values.isIis,
      infoParts: [...values.infoParts],
      riskReasons: [...values.riskReasons],
      isAlgostrategy: values.isAlgo,
      isRestricted: values.isRestricted,
      maxIndustryWeight: 0, // not used
      maxPositionWeight: values.maxPosWeight,
      minInvestProfileId: values.profile == undefined ? undefined : (values.profile as Profile).id,
      open: !values.closed,
      order: 1000, // #fixme
      showFullPortfolio: false,
      recommended: values.isRecommended,
      testMode: values.testMode,
      goal: values.welcomeLetter,
      tradesFrequency: '',
      // leverage tariffs
      rateShort: values.rateShort !== 0 ? values.rateShort : null,
      rateLong: values.rateLong !== 0 ? values.rateLong : null,
      hideIndexInWL: values.hideIndexInWL,
      hideIndexOnChart: values.hideIndexOnChart,
      tests: values.tests?.map((t) => t.id) ?? [],
      evaParams: {
        stockMarketMMVB: values.stockMarketMMVB,
        fortsRTS: values.fortsRTS,
        creditGO: values.creditGO,
        currencyMarket: values.currencyMarket,
        spbRTS: values.spbRTS,
        currencyMarketEBS: values.currencyMarketEBS,
        stockMarketOffexchange: values.stockMarketOffexchange,
        mmvbUSD: values.mmvbUSD,
        fortsRTSEBS: values.fortsRTSEBS,
        lse: values.lse,
        usMarkets: values.usMarkets,
      },
      isBlogger: values.isBlogger,

      autofollowPS: values.isAutofollowPS,
      autoconsultPS: values.isAutoconsultPS,
      servicePackageTariffs: mapFormValuesToServicePackages(packages || [], values),
    };

    if (values.index) {
      data.indexId = Number(values.index.id);
    }
    if (values.isRestricted) {
      data.clients = values.restrictedClients;
    }

    aggregateStrategiesApi
      .update(data)
      .then(errorHandler)
      //.then(() => notify('Изменения сохранены'))
      .catch(errorHandler)
      .finally(() => {
        setUpload(false);
      });
  };

  if (
    !strategy ||
    infoStore.size === 0 ||
    tariffStore.size === 0 ||
    strategiesStore.length === 0 ||
    userInfoStore.total === 0
  ) {
    if (loadError) {
      return (
        <>
          <div className="strategies-params__item-name">
            <p>Стратегия не найдена</p>
            <p>Стратегия с идентификатором {id} не существует</p>
          </div>
        </>
      );
    }

    if (tariffStore.size === 0) {
      return (
        <>
          <div className="strategies-params__item-name">
            <p>Загружаем тарифы для стратегии...</p>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="strategies-params__item-name">
          <p>Загружаем данные стратегии...</p>
        </div>
      </>
    );
  }

  const init = (strategy: AggregateStrategy) => {
    function getTariffAf(id?: number): { id: number; name: string; guid: string; category: string } | undefined {
      if (id) {
        const tariff = tariffStore.data.find((q) => q.id === id);
        if (tariff)
          return { id: tariff.id, name: tariff.description, guid: tariff.guid, category: tariff.category ?? '' };
      }

      return undefined;
    }

    function getTariffAc(id?: number): { id: number; name: string; guid: string } | undefined {
      if (id) {
        const tariff = tariffStore.data.find((q) => q.id === id);
        if (tariff) return { id: tariff.id, name: tariff.description, guid: tariff.guid };
      }

      return undefined;
    }

    function getTradeType(id: number): { id: number; name: string } | undefined {
      const tradeType = infoStore.data.tradeTypes.find((t) => t.id === id && t.enabled);
      if (tradeType) return { id: tradeType.id, name: tradeType.description };

      return undefined;
    }

    const fieldValues: Partial<FormFields> = {
      id: strategy.id,
      //author
      title: strategy.name,
      infoParts: strategy.infoParts ?? [],
      riskReasons: strategy.riskReasons ?? [],
      imageUrl: strategy.imageUrl ?? '',
      description: strategy.descriptionHtml ?? '',
      disclaimer: strategy.disclaimer ?? '',
      welcomeLetter: strategy.goal ?? '',
      subscription: strategy.chartComment,
      profile:
        strategy.minInvestProfileId >= 0
          ? infoStore.data.profiles.find((q) => q.id === strategy.minInvestProfileId)
          : undefined,
      currency: strategy.currency ? currencyStore.find((q) => q.code === strategy.currency) : undefined,
      minSumma: strategy.subscriptionThreshold,
      recommendedAmount: strategy.recommendedAmount,
      repoWithCcp: strategy.repoWithCcp,
      capacity: strategy.capacity,
      estimatedProfit: strategy.estimatedProfit,
      estimatedTradesPerWeek: strategy.estimatedTradesPerWeek,
      period: strategy.durationId >= 0 ? infoStore.data.durations.find((q) => q.id === strategy.durationId) : undefined,
      category: strategy.forQualifiedInvestorsOnly ? categories[0] : categories[1],
      markets: strategy.marketIds
        ? infoStore.data.markets.filter((q) => strategy.marketIds.find((m) => m === q.id))
        : [],
      tools: strategy.marketToolIds
        ? infoStore.data.marketTools.filter((q) => strategy.marketToolIds.find((m) => m === q.id))
        : [],
      boards: strategy.boardIds ? infoStore.data.boards.filter((q) => strategy.boardIds.find((m) => m === q.id)) : [],
      securities: [],
      maxSignalWeights: strategy.maxSignalWeights ?? [],
      index: strategy.indexId ? infoStore.data.indexes.find((q) => q.id === strategy.indexId) : undefined,
      hideIndexInWL: strategy.hideIndexInWL,
      hideIndexOnChart: strategy.hideIndexOnChart,
      isAutofollow: strategy.autofollow,
      isAutoconsult: strategy.autoconsult,
      tariffAf: strategy.afTariffId ? getTariffAf(strategy.afTariffId) : undefined,
      tariffAc: strategy.acTariffId ? getTariffAc(strategy.acTariffId) : undefined,
      maxPosWeight: strategy.maxInstrumentWeight,
      maxSignalWeight: strategy.maxSignalWeight,
      leverage: strategy.leverage,
      recalcMode: recalcModes.find((s) => s.id === strategy.recalcMode),
      tradeType: strategy.tradeType ? getTradeType(strategy.tradeType) : undefined,
      plStartDate: strategy.plStartDate ? new Date(strategy.plStartDate) : undefined,
      publishingDate: strategy.publishingDate ? new Date(strategy.publishingDate) : undefined,
      isAlgo: strategy.isAlgostrategy,
      testMode: strategy.testMode,
      closed: !strategy.open,
      hasParent: false,
      // отображение
      isIis: strategy.iis,
      //isCrisis: ?
      isRecommended: strategy.recommended,
      isHidden: strategy.hidePortfolio,
      // restricted settings
      isRestricted: strategy.isRestricted,
      restrictedClients: strategy.clients ?? [],
      // leverage tariffs
      rateShort: strategy.rateShort,
      rateLong: strategy.rateLong,
      // tests
      tests:
        strategy.tests
          ?.map((t) => infoStore.data.tests.find((ti) => ti.id === t))
          .filter((t) => !!t)
          .map((t) => {
            return { id: t?.id ?? '', name: t?.name ?? '' };
          }) ?? [],
      stockMarketMMVB: strategy.evaParams.stockMarketMMVB,
      fortsRTS: strategy.evaParams.fortsRTS,
      creditGO: strategy.evaParams.creditGO,
      currencyMarket: strategy.evaParams.currencyMarket,
      spbRTS: strategy.evaParams.spbRTS,
      currencyMarketEBS: strategy.evaParams.currencyMarketEBS,
      stockMarketOffexchange: strategy.evaParams.stockMarketOffexchange,
      mmvbUSD: strategy.evaParams.mmvbUSD,
      fortsRTSEBS: strategy.evaParams.fortsRTSEBS,
      lse: strategy.evaParams.lse,
      usMarkets: strategy.evaParams.usMarkets,
      isBlogger: strategy.isBlogger,

      isAutofollowPS: strategy.autofollowPS,
      isAutoconsultPS: strategy.autoconsultPS,
    };

    if (strategy.parentId) {
      const parentStrategy = strategiesStore.find((s) => s.id === strategy.parentId);
      if (parentStrategy) {
        fieldValues.parent = { id: parentStrategy.id, name: parentStrategy.name };
        fieldValues.hasParent = true;
      }
    }

    const author = userInfoStore.users.find((s) => s.id === strategy.managerId);

    if (author) {
      fieldValues.author = { id: author.id.toString(), name: author.displayName };
    }

    const managers = userInfoStore.users.filter((s) => strategy.managerIds?.includes(s.id));

    if (managers.length) {
      const managerOptions = managers.map(({ id, displayName }) => ({ id: id.toString(), name: displayName }));
      fieldValues.managerIds = managerOptions;
    }

    if (strategy.securities) {
      const securities = JSON.parse(strategy.securities) as string[];
      if (securities) {
        fieldValues.securities = securities.map((s) => {
          return { id: s, name: s, value: s };
        });
      }
    }

    return fieldValues;
  };

  const initValues = init(strategy);

  return (
    <>
      <div className="strategies-params__item-name">
        <p>{strategy.name}</p>
      </div>
      <Formik
        //enableReinitialize={true}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {(props) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          React.createElement(FormTemplate, {
            ...props,
            strategy,
            error,
            cancel,
          })
        }
      </Formik>
    </>
  );
};
