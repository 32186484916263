import React from 'react';

import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Footer: React.FC = () => (
  <div className="footer">
    © 2019-2024{' '}
    <a className="footer__link" href="broker.ru">
      ФГ БКС
    </a>
    <a className="footer__link" href="mailto:info@fintarget.ru">
      <FontAwesomeIcon icon={faEnvelope} className="footer__icon" /> info@fintarget.ru
    </a>
    <a className="footer__link" href="tel:+74957855336,17480">
      <FontAwesomeIcon icon={faPhone} className="footer__icon" /> +7 495 785 5336 доб. 17480
    </a>
  </div>
);
