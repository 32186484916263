import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { Banner } from '../../../types/banner';
import { useUpdateBanner } from '../Form/queries';

export interface Props {
  banner: Banner;
  onUpdate: () => void;
  onClose: () => void;
}

export function RemoveBannerDialog({ banner, onUpdate, onClose }: Props) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const { mutateAsync } = useUpdateBanner();

  const onSubmit = async () => {
    setLoading(true);
    const data = {
      ...banner,
      active: !banner?.active,
    };

    try {
      const result: { errorMessage?: string } = await mutateAsync(data);

      if (result.errorMessage) {
        setError(result.errorMessage);
        return;
      }

      onUpdate();
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      setError(err?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const status = banner?.active ? 'Неактивный' : 'Активный';

  return (
    <Dialog maxWidth="xs" open>
      <DialogTitle align="center">Изменение статуса</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        Вы уверены что хотите сменить статус баннера на <b>{status}</b>?
        <br />
        {error && <p className="error-message">{error}</p>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading} startIcon={loading && <CircularProgress size={20} />}>
          Отмена
        </Button>
        <Button onClick={onSubmit} disabled={loading} startIcon={loading && <CircularProgress size={20} />}>
          Ок
        </Button>
      </DialogActions>
    </Dialog>
  );
}
