import { Nullable } from '../utils/types';

export enum RecalcMode {
  EntryPriceRecalc = 0,
  EntryPriceNoRecalc = 1,
  CurrentPriceRecalc = 2,
  CurrentPriceNoRecalc = 4,
}

export type InfoPart = {
  title: string;
  value: string;
};

export type ServicePackage = {
  servicePackageId: number; //- id сервиса пакета услуг и справочника выше
  tariffId: number; // - id тарифа из справочника наших тарифов
  strategyType: number; //- тип услуги (1- автоследование, 2 - автоконсультирование...)
};

export type EvaParams = {
  stockMarketMMVB: boolean;
  fortsRTS: boolean;
  creditGO: boolean;
  currencyMarket: boolean;
  spbRTS: boolean;
  currencyMarketEBS: boolean;
  stockMarketOffexchange: boolean;
  mmvbUSD: boolean;
  fortsRTSEBS: boolean;
  lse: boolean;
  usMarkets: boolean;
  evaUpdatedAt?: string;
};

export type BorrPublication = {
  success: boolean;
  description?: string;
  updatedAt: string;
};

export type AggregateStrategy = {
  id: string;
  parentId?: string;
  managerId: number;
  managerIds?: number[];
  managerName: string;
  creatorId: number;
  creationDate: string;
  currency: string;
  leverage: number;
  noWeightCorrection: number;
  securities: string;
  maxSignalWeights: { classCode: string; value: number }[];
  comissions?: string;
  maxInstrumentWeight?: number;
  maxSignalWeight?: number;
  name: string;
  subscriptionThreshold: number;
  recommendedAmount: number;
  repoWithCcp: boolean;
  recalcMode: RecalcMode;
  tradeType?: number;
  active: boolean;
  evaParams: EvaParams;
  borrPublication: BorrPublication;
  isFrozen: boolean;
  isBlogger: boolean;

  // FT
  rating: number;
  apiKey?: string;
  autoconsult: boolean;
  acTariffId?: number;
  autofollow: boolean;
  afTariffId?: number;
  category?: string;
  chartComment: string;
  capacity?: number;
  imageUrl?: string | null;
  descriptionHtml: string;
  disclaimer: string;
  durationId: number;
  estimatedDrawdown: number; // не используется
  estimatedProfit: number;
  estimatedTradesPerWeek?: number;
  actualProfit: number; // calculated
  forQualifiedInvestorsOnly: boolean;
  goal: string;
  marketIds: number[];
  marketToolIds: number[];
  boardIds: number[];
  hidePortfolio: boolean;
  iis: boolean;
  indexId?: number;
  hideIndexOnChart: boolean;
  hideIndexInWL: boolean;
  infoHtml: string;
  infoParts: InfoPart[];
  riskReasons: InfoPart[];
  isAlgostrategy: boolean;
  maxIndustryWeight: number;
  maxPositionWeight: number;
  minInvestProfileId: number;
  open: boolean;
  order: number;
  pictureFormat?: string;
  pictureBase64?: string;
  price: Nullable<string>;
  recommended: boolean;
  showFullPortfolio: boolean;
  startDate?: string;
  plStartDate?: string;
  publishingDate?: string;
  tag: string;
  testMode: boolean;
  tradesFrequency: string;

  // when restricted is true
  isRestricted: boolean;
  clients: { id: string; name: string; value: string }[];

  tests: string[];

  // leverage tariff short&long
  rateShort: Nullable<number>;
  rateLong: Nullable<number>;

  autoconsultPS: boolean;
  autofollowPS: boolean;
  servicePackageTariffs: Nullable<ServicePackage[]>;
};
