import { Checkbox, FormControl, FormControlLabel, FormGroup, Typography, Stack } from '@mui/material';

import { Nullable } from 'utils/types';

import { Option } from '../../../../types/option';

interface Props<T extends Record<string, any>> {
  strategies: Nullable<Option[]>;
  formStrategies: string[];
  formShowStrategies: string[];
  setValue: (field: keyof T, value: string[]) => void;
}

export const StrategiesCheckboxGroup = <T extends Record<string, any>>({
  strategies,
  formStrategies,
  formShowStrategies,
  setValue,
}: Props<T>) => {
  const handleCheckboxChange = (strategyId: string, checked: boolean) => {
    const updatedValues = checked
      ? [...formShowStrategies, strategyId]
      : formShowStrategies.filter((id) => id !== strategyId);
    setValue('showStrategies', updatedValues);
  };

  return (
    <FormControl component="fieldset" sx={{ mb: 2 }}>
      <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1 }}>
        Показать в стратегии:
      </Typography>
      <FormGroup>
        <Stack spacing={1}>
          {strategies?.map((strategy) => {
            const isChecked = formStrategies.includes(strategy.id);

            if (!isChecked) return null;

            return (
              <FormControlLabel
                key={strategy.id}
                control={
                  <Checkbox
                    checked={formShowStrategies.includes(strategy.id)}
                    onChange={(e) => handleCheckboxChange(strategy.id, e.target.checked)}
                  />
                }
                label={strategy.name}
              />
            );
          })}
        </Stack>
      </FormGroup>
    </FormControl>
  );
};
