/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { modalApi } from './modals';
import { closeDelayedSignal } from '../api';
import { DelayedSignal } from '../types/delayedSignal';
import { Nullable } from '../utils/types';

type TDelayedSignals$State = {
  totalPage: number;
  signals: DelayedSignal[];
};

const initialState: TDelayedSignals$State = {
  totalPage: 0,
  signals: [],
};

export const DelayedSignals$ = createStore<TDelayedSignals$State>(initialState);

const { add, close } = createApi(DelayedSignals$, {
  // @ts-ignore
  add: (state, signal) => {
    return {
      ...state,
      signals: [...state.signals, signal],
    };
  },
  // @ts-ignore
  close: (state, { result, params }) => {
    const updatedState = { ...state };

    if (result.success) {
      updatedState.signals = updatedState.signals.filter((item) => item.id !== params.id);
      // @ts-ignore
      modalApi.hide('');
    }

    return updatedState;
  },
});

export const delayedSignalsApi = {
  close: createEffect<Nullable<string>, {}, {}>().use(closeDelayedSignal),
  add: createEffect(),
};

forward({
  from: delayedSignalsApi.close.done,
  to: close,
});

forward({
  from: delayedSignalsApi.add,
  to: add,
});
